import { Build } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CounterPill from "./CounterPill";
import IconButton from "./nav/IconButton";

export default function CounterCheckSection({ id, data, onClickAction }) {
    const isDisabled = data.isDisabled;
    const isAlwaysShown = data.isAlwaysShown;
    const tabName = data.tabName;
    const value_a = data.value_a;
    const label_a = data.label_a?.join(" and ");
    const tab_a = data.tab_a;
    const value_b = data.value_b;
    const label_b = data.label_b?.join(" and ");
    const tab_b = data.tab_b;


    if ( !isDisabled && ((!isAlwaysShown && value_a > value_b ) || (isAlwaysShown && value_a !== value_b))) {
        return (
            <Box
                className="light-background" 
                sx={{
                    borderRadius: '20px',
                    padding: 2,
                    marginY: 2,
                }}
            >
                <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid sm={12} display={{ xs: 'flex', md: 'none' }}>
                        <CounterPill value_a={value_a} value_b={value_b} />
                    </Grid>
                    <Grid sm={12} lg={'auto'}>
                        <Typography>
                            Your inputs for <br/>
                            <span style={{ fontWeight: 700 }}>{label_a}</span> ({tab_a})<br/>
                            {
                                (value_a > value_b) ? 
                                    <span>has <span style={{ color: 'firebrick' }}>exceeded </span></span>
                                :
                                (value_a < value_b) ? 
                                    <span>is <span style={{ color: 'darkgoldenrod' }}>less than </span></span>
                                :
                                (value_a !== value_b) ? 
                                    <span>is <span style={{ color: 'firebrick' }}>not equal to </span></span>
                                :
                                "is equal to "
                            }
                            {
                                label_b ?
                                <>
                                    your input for <br/>
                                    <span style={{ fontWeight: 700 }}>{label_b}</span> ({tab_b}).
                                </>
                                :
                                <>
                                    100. <br/>
                                    (Please ensure that the percentages add up to 100.)
                                </>
                            }
                        </Typography>
                    </Grid>
                    <Grid sm={12} lg={'auto'}>
                        <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
                            <Grid sm={12} >
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                                    <IconButton icon={<Build />} id={id} label={`Fix this in "${tab_a}" tab`} onClickAction={() => onClickAction(tabName)}/>
                                </Box>
                            </Grid>
                            <Grid sm={12} display={{ xs: 'none', md: 'flex' }}>
                                <CounterPill value_a={value_a} value_b={value_b} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}