import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import '../../../print.css';
import { sortObj } from '../../helpers/FormHelper';

const COLORS = {
    'Category 1: Transportation': '#203864',
    'Category 2: Accommodation': '#800000',
    'Category 3: On-site Activities': '#7F6000',
    'Total Carbon Emissions (kg CO\u2082e)': '#747474',
    'Avg Emissions (kg CO\u2082e)': '#747474',
    'Net Event Carbon Emissions (kg CO\u2082e)': '#747474',

    // old version
    'Total Carbon Emissions (kg CO2e)': '#747474',
    'Avg Emissions per Person per Day (kg CO2e)': '#747474',
    'Avg Emissions per Person per Day (kg CO\u2082e)': '#747474',
    'Net Event Carbon Emissions (kg CO2e)': '#747474',
};
const ModTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.8em',
        padding: '4px 16px'
    },
}));
const StyledTableCell = styled(ModTableCell)(({ bgcolor, theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#e2e2e2',
        fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: 'bgcolor',
        color: bgcolor ? theme.palette.common.white : theme.palette.common.black,
        fontWeight: 700,
    },
}));

export default function ResultTable({ title, totalData, tableData, imageTag, children, withPercentage=false }) {
    const [sortedTableData, setSortedTableData] = useState({});
    const [totalEmissions, setTotalEmissions] = useState(0);

    const formatNumber = (num) => {
        return parseFloat(num).toLocaleString('en-US', {maximumFractionDigits: 0});
    }

    useEffect(() => {
        setSortedTableData(sortObj(tableData));
    }, [tableData]);

    useEffect(() => {
        if (withPercentage) setTotalEmissions(totalData?.['41_total_emissions'].value[2]);
    }, [withPercentage, totalData]);
    
    return (
        <>
        <Typography variant="h6" sx={{ fontSize: '1.1em' }} gutterBottom>
            {title}
        </Typography>
        <TableContainer>
            <Table id={imageTag} size="small" aria-label="simple table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell align="right">Physical</StyledTableCell>
                    <StyledTableCell align="right">Virtual</StyledTableCell>
                    <StyledTableCell align="right">Total</StyledTableCell>
                    {withPercentage && <StyledTableCell align="center" sx={{ borderLeft: '1px solid #e2e2e2' }} className='show-on-print-table'>%</StyledTableCell>}
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    Object.values(sortedTableData).map((row, idx) => {
                        let label = row.label;
                        let phys = row.value[0];
                        let vir = row.value[1] ? row.value[1] : 0;
                        let total =  row.value[2] ? row.value[2] : ( row.value[1] ? (row.value[0] + row.value[1]) : phys );
                        let perc = total/totalEmissions * 100;

                        return (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {
                                        row.type === "sub" ?
                                        <>
                                            <StyledTableCell component="th" scope="row" bgcolor={COLORS[row.label]}>{label}</StyledTableCell>
                                            <StyledTableCell align="right" bgcolor={COLORS[row.label]}>{formatNumber(phys)}</StyledTableCell>
                                            <StyledTableCell align="right" bgcolor={COLORS[row.label]}>{formatNumber(vir)}</StyledTableCell>
                                            <StyledTableCell align="right" bgcolor={COLORS[row.label]}>{formatNumber(total)}</StyledTableCell>
                                            {withPercentage && <StyledTableCell align="center" bgcolor={COLORS[row.label]}  sx={{ borderLeft: '1px solid #e2e2e2' }} className='show-on-print-table'>{formatNumber(perc)}</StyledTableCell>}
                                        </>
                                        :
                                        <>
                                            <ModTableCell component="th" scope="row">{label}</ModTableCell>
                                            <ModTableCell align="right">{formatNumber(phys)}</ModTableCell>
                                            <ModTableCell align="right">{formatNumber(vir)}</ModTableCell>
                                            <ModTableCell align="right">{formatNumber(total)}</ModTableCell>
                                            {withPercentage && <ModTableCell align="center"  sx={{ borderLeft: '1px solid #e2e2e2' }} className='show-on-print-table'>{formatNumber(perc)}</ModTableCell>}
                                        </>
                                    }
                            </TableRow>
                        )
                    })
                }
                </TableBody>
                {children}
            </Table>
        </TableContainer>
        </>
    )
}