import styled from '@emotion/styled';
import Calculate from '@mui/icons-material/Calculate';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../print.css';
import CopyToClipboard from '../components/popups/CopyToClipboard';
import ResultBenchmarks from '../components/results/ResultBenchmarks';
import ResultBreakdown from '../components/results/ResultBreakdown';
import ResultInputs from '../components/results/ResultInputs';
import ResultOffset from '../components/results/ResultOffset';
import ResultTable from '../components/results/ResultTable';

const GridCard = styled(Card) ({
    height: '100%',
    width: '100%',
    padding: 10,
    boxSizing: 'border-box',
    boxShadow: 'none',
    border: '1px solid #e2e2e2'
})

export default function ResultLayout({ calcResults }) {
    document.title = calcResults.referenceId;
    const showInputs = JSON.parse(localStorage.getItem('mccshow'));

    const navigate = useNavigate();
    const [results, setResults] = useState(calcResults);

    const [tableData, setTableData] = useState({});
    const referenceId = results.referenceId;

    const handlePrint = () => {
        document.getElementById('export_button_text').innerText = 'Preparing your PDF...';
        setTimeout(() => {
            window.print();
            document.getElementById('export_button_text').innerText = 'Export Results as PDF';
        }, 1500);
    };

    useEffect(() => {
        setTableData(Object.assign({}, results.breakdown_subcat, results.breakdown_cat, results.total));
    }, [results]);

    return (
        <>
        <Box sx={{ py: 2 }}>
            <Grid container spacing={2} sx={{ justifyContent: 'space-between' }} className="hide-on-print">
                <Grid >
                    <Typography className="title-tab">Calculation Results</Typography>
                    <Typography variant='body2' color="text.secondary">Please note that these results are best viewed on a desktop.</Typography>
                </Grid>
                <Grid sx={{ display: { sm: 'block', md: 'flex' } }}>
                    {
                        referenceId &&
                        <Box>
                        Reference ID: <b>{referenceId}</b> <CopyToClipboard contentLabel={"Ref ID"} contentToCopy={ referenceId } />
                        </Box>
                    }
                    <Box>
                        <Button className='pill_button submit_button' onClick={handlePrint}><PictureAsPdf />&nbsp;<span id="export_button_text">Export Results as PDF</span></Button>
                    </Box>
                </Grid>
            </Grid>
            <Box className="show-on-print">
                <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid>
                        <Typography className="title-section" sx={{ fontWeight: 900 }}>Calculation Results</Typography>
                    </Grid>
                    <Grid sx={{ display: 'flex', fontSize: '0.8em' }}>
                        {
                            referenceId &&
                            <Box>Reference ID: <span style={{ boxSizing: 'border-box',  borderRadius: '24px', backgroundColor: '#42b868', color: 'white', padding: '10px 20px' }}><b>{referenceId}</b></span></Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>

        <Grid container spacing={2} sx={{ justifyContent: 'stretch' }}>
            <Grid xs={12} md={6} lg={5}>
                <GridCard>
                <ResultTable title={
                    <>
                    Carbon Footprint Breakdown by Category
                    <span style={{ fontSize: 14, color: '#00000099' }}>
                    &emsp;(kg CO<sub>2</sub>e)
                    </span>
                    </>
                    } 
                    tableData={tableData}
                    totalData={results.total}
                    withPercentage={true}
                    imageTag='result_cat_table' 
                    />
                </GridCard>
            </Grid>
            <Grid xs={12} md={6} lg={7} sx={{ breakAfter: 'page' }}>
                <GridCard>
                <ResultBreakdown
                    outerRing={results.breakdown_subcat} innerRing={results.breakdown_cat}
                />
                </GridCard>
            </Grid>

            <Grid xs={12} md={6} lg={5}>
                <GridCard>
                <ResultTable title='Per Capita Carbon Footprint per Day'
                    tableData={Object.assign({}, results.global, results.averages)} imageTag='result_avg_table' />
                </GridCard>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
                <GridCard>
                <ResultBenchmarks barDataObj={results.benchmarks} />
                </GridCard>
            </Grid>

            <Grid xs={12} md={12} lg={3}>
                <GridCard>
                <ResultOffset netValue={results.net} offsetValue={results.offset} calcResults={calcResults} results={results} setResults={setResults} />
                </GridCard>
            </Grid>

            {showInputs &&
            <Grid xs={12}>
                <ResultInputs inputList={{...results.processedInputs, ...results.saveBody}} />
            </Grid>
            }
        </Grid>

        <Box className="show-on-print">
            <Typography variant="h6" color="text.secondary" sx={{ fontSize: '1.1em', fontWeight: 900, my: 3, breakBefore: 'page' }}>
                Disclaimers:
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ lineHeight: '0.6em' }}>
                The results presented in this report are generated automatically by our model based on user input data alone and are not verified by any institute or agency. The accuracy of the results varies depending on the quality of the data entered and the event-specific emissions. The carbon calculator should be used at your own discretion and SGFIN does not guarantee the accuracy of the results. SGFIN is not responsible for any errors or omissions, or for any loss or damage of any kind arising from or in connection with the use of the carbon calculator. Please contact us at <a href='mailto:sgfin@nus.edu.sg' >sgfin@nus.edu.sg</a> for more accurate carbon emission estimation or results validation.
                <br /><br />
                © Copyright by Sustainable and Green Finance Institute (2024)
            </Typography>
        </Box>

        <Box className='hide-on-print' sx={{ margin: '2.5em 0', display: { xs: 'block', sm: 'flex' }, justifyContent: 'center' }} >
            <Button className='pill_button submit_button' onClick={() => navigate(process.env.REACT_APP_CC_ROOT +'/calculator')}><Calculate />&nbsp;Make another calculation</Button>
        </Box>
        </>
    )
}