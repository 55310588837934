import { ArrowBack } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Unstable_Grid2 as Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Container from "@mui/material/Container";
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import EllipsisLoader from '../../carbonCalc/components/loaders/EllipsisLoader';
import { isValidEmail } from '../../common/consts/helpers';
import bgimg from '../../common/images/glass1.jpg';
import { countryOptions, dataUseOptions, userTypeOptions } from '../consts/formConsts';

const boxStyle = {
    border: '1px solid #fff',
    backgroundColor: '#ffffffdd',
    borderRadius: '24px',
    p: 2,
    my: 4,
    backdropFilter: 'blur(3px)',
    transition: 'box-shadow 0.5s, transform 0.5s',
};

export default function RequestAccessPage({ checkEmailAPI }) {
    const { state } = useLocation();

    // Display control
    const [isShowForm, setShowForm] = useState(null);
    const [isLoadingRegistration, setLoadingRegistration] = useState(false);
    const [isLoadingEmailCheck, setLoadingCheck] = useState(false);

    //  Inputs
    const [isReadyToSubmit, setReadyToSubmit] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [inputFirstName, setInputFirstName] = useState('');
    const [inputLastName, setInputLastName] = useState('');
    const [inputCompany, setInputCompany] = useState('');
    const [inputCountry, setInputCountry] = useState('');
    const [inputUserType, setInputUserType] = useState('');
    const [inputDataUse, setInputDataUse] = useState('');
    const [inputAck, setInputAck] = useState(false);
    const [inputConsent, setInputConsent] = useState(false);

    const handleCheckbox = (setterFunc) => {
        setterFunc(lastBool => !lastBool);
    }

    const handleEmailAccessCheck = async() => {
        if (inputEmail) {
            setLoadingCheck(true);
            const { success, message } = await checkEmailAPI(inputEmail);
            setLoadingCheck(false);
            setAlertResponse(success ? 'success' : 'warning', message);
        } else {
            setAlertResponse('warning', "Please provide the email address that you used to request access with.")
        }
    }

    const [isAlertOpen, setOpen] = useState(false);
    const [apiResponse, setResponse] = useState({});

    const setAlertResponse = (severity='', message='') => {
        setResponse({ severity: severity, message: message });
        setOpen(Boolean(message));
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    const url = process.env.REACT_APP_EDH_LAMBDA;
    const handleSubmit = () => {
        setLoadingRegistration(true);
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                inputEmail: inputEmail,
                inputFirstName: inputFirstName,
                inputLastName: inputLastName,
                inputCompany: inputCompany,
                inputCountry: inputCountry,
                inputUserType: inputUserType,
                inputDataUse: inputDataUse,
                inputAck: inputAck,
                inputConsent: inputConsent,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            // console.log(response)
            return response.json();
        }).then(data => {
            // console.log(data);
            setLoadingRegistration(false);
            setAlertResponse(data?.success ? 'success' : 'warning', data?.message);
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    };

    useEffect(()=>{
        if (state) setAlertResponse('error', state?.errorMsg);
        else setAlertResponse();
    },[state])

    useEffect(() => {
        const requiredFields = [inputEmail, inputFirstName, inputLastName, inputCompany, inputCountry, inputUserType, inputDataUse, inputAck, inputConsent]; // CHECK all in dependency list
        var allFilled = requiredFields.every(Boolean);
        setReadyToSubmit(allFilled && isValidEmail(inputEmail))
    }, [inputEmail, inputFirstName, inputLastName, inputCompany, inputCountry, inputUserType, inputDataUse, inputAck, inputConsent])

    return (
        <Box
            sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                flexDirection: 'column',
                // background: "linear-gradient(45deg, #D3FCE4, #F6F3B4);",
                background: `var(--bg-light) url(${bgimg}) no-repeat center/cover fixed`, width: '100%', minHeight: '90vh'
            }}
        >
            <Container maxWidth='lg'>
                <Box sx={boxStyle}>
                    <Typography variant='h4' gutterBottom>SGFIN Environmental Data Hub</Typography>
                    <Collapse in={isShowForm === null}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button className='pill_button' onClick={()=>setShowForm(true)} sx={{ p: 4 }} fullWidth>
                                I am a New User
                            </Button>
                            <Button className='pill_button' onClick={()=>setShowForm(false)} sx={{ p: 4 }} fullWidth>
                                I am a Returning User
                            </Button>
                        </Box>
                    </Collapse>
                    <Collapse in={isShowForm === true} >
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={()=>setShowForm(null)}
                                >
                                    <ArrowBack fontSize="inherit" />
                                </IconButton>
                                <Typography variant='h5'>&ensp;FOR NEW USERS: Request for access</Typography>
                            </Box>
                            
                            {
                                apiResponse.severity === 'success' ?
                                <>
                                <Typography variant='body2'>
                                    Please check your email for the access link.
                                </Typography>
                                    <Button className={'submit_button'} sx={{ mt: 2 }} disabled >
                                        Your Request has been Received
                                    </Button>
                                </>
                                :
                                <>
                                <Typography variant='body2'>Please fill in the form below to request for access to our data hub. All fields are required.</Typography>
                                <Typography variant='body2'>A link will be sent to your email granting you access to our data hub.</Typography>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={12}>
                                        <AccessFormTextField
                                            idname='email_field'
                                            label='Business Email Address *'
                                            value={inputEmail}
                                            valueSetter={setInputEmail}
                                            error={!isValidEmail(inputEmail)}
                                            helperText={!isValidEmail(inputEmail) ? "Please provide a valid email" : ""}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormTextField
                                            idname='name_field'
                                            label='First Name *'
                                            value={inputFirstName}
                                            valueSetter={setInputFirstName}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormTextField
                                            idname='name_field'
                                            label='Last Name *'
                                            value={inputLastName}
                                            valueSetter={setInputLastName}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormTextField
                                            idname='company_field'
                                            label='Company/Organisation *'
                                            value={inputCompany}
                                            valueSetter={setInputCompany}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormSelect
                                            idname='country_field'
                                            label='Country *'
                                            value={inputCountry}
                                            valueSetter={setInputCountry}
                                            options={countryOptions}
                                            dropdownOnly={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormSelect
                                            idname='usertype_field'
                                            label='Role *'
                                            value={inputUserType}
                                            valueSetter={setInputUserType}
                                            options={userTypeOptions}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormSelect
                                            idname='datause_field'
                                            label='Data Use Case *'
                                            value={inputDataUse}
                                            valueSetter={setInputDataUse}
                                            options={dataUseOptions}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FormHelperText>
                                        By submitting this form, you agree that Sustainability and Green Finance Institute may collect, use, process and/or disclose your personal data for the purpose of registration and access to this data hub.  Should you like to access and correct your personal data or withdraw consent to the collection, use, process and/or disclosure of your personal data, please write to us at <a href='mailto:sgfin@nus.edu.sg' >sgfin@nus.edu.sg</a>.
                                        </FormHelperText>
                                        <FormControlLabel 
                                            value={'Consent'} label={"I consent to receiving marketing and promotional information, and updates regarding the data hub via email from Sustainability and Green Finance Institute."}
                                            control={
                                                <Checkbox 
                                                    checked={inputConsent} 
                                                    onChange={() => handleCheckbox(setInputConsent)}  
                                                    name={'Consent'} 
                                                    sx={{ padding: '8px' }}
                                                    required
                                                />
                                            } 
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FormControlLabel 
                                            value={'Acknowledged'} label={"I acknowledge that any use of the data provided in this data hub will be properly attributed to Sustainability and Green Finance Institute."}
                                            control={
                                                <Checkbox 
                                                    checked={inputAck} 
                                                    onChange={() => handleCheckbox(setInputAck)}  
                                                    name={'Acknowledged'} 
                                                    sx={{ padding: '8px' }}
                                                    required
                                                />
                                            } 
                                        />
                                    </Grid>
                                </Grid>
                                <Button className={'pill_button submit_button' + (isReadyToSubmit ? '' : ' disabled_button')} type='submit' onClick={handleSubmit} sx={{ mt: 2 }} disabled={!isReadyToSubmit} >
                                    {
                                        isLoadingRegistration ?
                                        <>
                                        &nbsp;<EllipsisLoader />&nbsp;
                                        </>
                                        :
                                        'Submit Request'
                                    }
                                </Button>
                                </>
                            }
                            <Divider>Are you a returning user?</Divider>
                            <Button className='pill_button outline_button' onClick={() => setShowForm(false)}>Verify your email</Button>
                        </Stack>
                    </Collapse>
                    <Collapse in={isShowForm === false} >
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={()=>setShowForm(null)}
                                >
                                    <ArrowBack fontSize="inherit" />
                                </IconButton>
                                <Typography variant='h5'>&ensp;FOR RETURNING USERS: Verify your email</Typography>
                            </Box>
                            <Typography variant='body2'>If you have already requested for access previously, please enter the email you registered with:</Typography>
                            <AccessFormTextField
                                idname='email_field'
                                label='Business Email Address'
                                value={inputEmail}
                                valueSetter={setInputEmail}
                                error={!isValidEmail(inputEmail)}
                                helperText={!isValidEmail(inputEmail) ? "Please provide a valid email" : ""}
                            />
                            <Button 
                                className={'pill_button' + (isValidEmail(inputEmail) ? '' : ' disabled_button')} 
                                onClick={handleEmailAccessCheck}
                                disabled={!isValidEmail(inputEmail)} 
                            >
                                { 
                                isLoadingEmailCheck ?
                                <>
                                &nbsp;<EllipsisLoader />&nbsp;
                                </>
                                :
                                'Proceed to Data'
                                }
                            </Button>
                            <Divider>Are you a new user?</Divider>
                            <Button className='pill_button outline_button' onClick={() => setShowForm(true)}>Go to Request Form</Button>
                        </Stack>
                    </Collapse>
                </Box>
                <Snackbar
                    open={isAlertOpen}
                    onClose={handleClose}
                >
                    <Alert
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        variant='filled'
                        onClose={handleClose}
                        severity={apiResponse.severity}
                    >
                        {apiResponse.message}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    )
}

const AccessFormTextField = ({ idname, value, label, valueSetter, ...props }) => {
    return (
        <FormControl fullWidth>
        <FormLabel htmlFor={idname} id={`${idname}-label`}>{label}</FormLabel>
        <TextField
            className={idname}
            name={idname} id={idname}
            onChange={e => valueSetter(e.target.value)}
            variant='outlined'
            value={value}
            type='text'
            size='small'
            required
            {...props}
        />
        </FormControl>
    )
}
const AccessFormSelect = ({ idname, value, label, valueSetter, options, dropdownOnly=false }) => {
    const placeholder = `Select one from the dropdown${ !dropdownOnly ? ' or type your own answer' : '' }:`
    return (
        <FormControl fullWidth>
            <FormLabel htmlFor={idname} id={`${idname}-label`}>{label}</FormLabel>
            <Autocomplete
                freeSolo={!dropdownOnly}
                name={idname}
                options={["", ...options]}
                value={value}
                onChange={(event, newValue) => {
                    valueSetter(newValue || '');
                }}
                inputValue={value}
                onInputChange={(event, newInputValue) => {
                    valueSetter(newInputValue || '');
                }}
                renderInput={(params) => 
                    <TextField 
                        {...params}
                        placeholder={placeholder}
                        aria-label={placeholder}
                        fullWidth
                    />
                }
            />
        </FormControl>
    )
}