import { Publish } from '@mui/icons-material';
import RotateLeft from '@mui/icons-material/RotateLeft';
import {
    Box,
    IconButton,
    InputBase,
    Stack,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import '../../../print.css';

export default function OffsetInputField({ calcResults, results, setResults, setNetEmissions_output }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [isLocked, setIsLocked] = useState(false);
    const totalEmissions_output = calcResults.total['41_total_emissions'].value[2];
    const [carbonOffset_input, setCarbonOffset_input] = useState(calcResults?.offset?.value);

    const handleOffsetInput = () => {
        if (isNaN(parseFloat(carbonOffset_input)) || parseFloat(carbonOffset_input) > parseFloat(totalEmissions_output)) {
            setErrorMsg("Invalid offset value.");
        } else {
            setErrorMsg('');
            const url = process.env.REACT_APP_CC_LAMBDA;
            const formSubmitData = {
                'calcStage': 3,
                'responseBody': results,
                'carbon_offset': carbonOffset_input
            }
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    httpMethod: 'POST',
                    path: '/calculate',
                    ...formSubmitData
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(
                function (response) {
                    return response.json();
                }
            ).then(
                function(data) {
                    let body = JSON.parse(data.body);
                    if (data.status === "success") {
                        setIsLocked(true);
                        setResults(body);
                    } else if (data.status === "error") {
                        // console.log(body)
                    }
                }
            ).catch(
                function(error) {
                    if (process.env.NODE_ENV === 'development') {
                        if (typeof error.json === "function") {
                            error.json().then(jsonError => {
                                console.log("Json error from API");
                                console.log(jsonError);
                            }).catch(genericError => {
                                console.log("Generic error from API");
                                console.log(error.statusText);
                            });
                        } else {
                            console.log("Fetch error");
                            console.log(error);
                        }
                    }
                }
            );
        }
    }
    
    useEffect(() => {
        setIsLocked( calcResults?.offset?.value );
    }, []);
    
    useEffect(() => {
        setNetEmissions_output(parseFloat(results?.net?.value).toLocaleString('en-US', {maximumFractionDigits: 0}))
    }, [results]);
    
    return (
        <>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        {
        isLocked ?
        <>
            <Box>{carbonOffset_input}</Box>
            <IconButton className='submit_button hide-on-print' onClick={() => setIsLocked(false)}>
            <RotateLeft />
            </IconButton>
        </>
        :
        <>
        <Box className='hide-on-print' sx={{ display: 'flex', p: '2px', borderRadius: '24px', border: '1px grey solid', '&:hover': { borderColor: 'black' } }}>
            <InputBase 
                id="carbonOffset_input" 
                value={carbonOffset_input} 
                onChange={(e) => setCarbonOffset_input(e.target.value)}
                error={(errorMsg !== '')}
            />
            <IconButton className='submit_button' onClick={handleOffsetInput}>
            <Publish />
            </IconButton>
        </Box>
        <Box className='show-on-print'>{carbonOffset_input}</Box>
        </>
        }
        </Stack>
        {
            errorMsg &&
            <Typography variant='caption' color='#f00'>
                {errorMsg}
            </Typography>
        }
        </>
    )
}