import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import ModalBase from '../../../common/components/cards/ModalBase';
import { OverallDataContext, TabContext } from '../../pages/CalculatorPage';
import CounterCheckSection from '../CounterCheckSection';

const ModalSubheading = styled(Typography) ({
    fontWeight: '700',
    marginTop: '16px'
})

export default function CounterCheckModal({ open, setOpen, handleSubmit }) {
    const counterData = useContext(OverallDataContext).counterData[0];
    const changeTabTo = useContext(TabContext).changeTabTo;

    const handleClose = () => setOpen(false);
    const handleEditClick = (e) => {
        handleClose();
        changeTabTo(e);
        window.scrollTo(0, 0); // scroll to top
    }

    return (
        <ModalBase 
            title="Please change these inconsistent values!"
            open={open}
            setOpen={setOpen}
        >
            {
                Object.values(counterData).map((data, idx) => {
                    return <CounterCheckSection key={idx} id={idx} data={data} onClickAction={handleEditClick} />
                })
            }
            
            <ModalSubheading>Please correct these values in the Onsite Activities / Transportation / Accommodation tabs before calculating.</ModalSubheading>
            <Box className='calc-buttongroup' marginTop={2} >
                <Button className='pill_button secondary_button' onClick={handleClose}>Continue Editing</Button>
            </Box>
            
            {/* <ModalSubheading>Do you want to proceed to calculate which will automatically change your Global inputs, or continue editing?</ModalSubheading>
            <Box className='calc-buttongroup' marginTop={2} >
                <Button className='pill_button secondary_button' onClick={handleClose}>Continue Editing</Button>
                <Button className='pill_button submit_button' onClick={() => {handleClose(); handleSubmit();}}>Proceed to Calculate</Button>
            </Box> */}
        </ModalBase>
    );
}