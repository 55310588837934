import Access from "../../pages/HomePage";
import TechNotes from "../../pages/TechNotesPage";

const pathRoot = process.env.REACT_APP_CCP_ROOT;
export const NAV_CONFIG_APP = {
    rootPath: pathRoot,
    routes:
    [
        {
            title: 'Home',
            path: pathRoot,
            element: <Access />,
        },
        // {
        //     title: 'test',
        //     path: pathRoot + '/test',
        //     element: <RequestAccessPage />,
        // },
        {
            title: 'Technical Notes',
            path: pathRoot + '/technotes',
            element: <TechNotes />,
        },
    ]
}
