import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";

export default function NavItem({ item, onClickAction, forMobile=false }) {
	const { pathname } = useLocation();
    return (
        <Button
            onClick={onClickAction}
            sx={{ display: 'flex', flexDirection: 'row', color: pathname===item.path ? 'var(--btn-blue)' : 'text.secondary' }}
        >
            <Box sx={{ 
                position: 'absolute', 
                border: pathname===item.path ? '2px solid' : 'none',
                // desktop style
                bottom: forMobile ? '' : 0, 
                width: forMobile ? '' : '30px', 
                // mobile style
                left: forMobile ? 0 : '', 
                height: forMobile ? '50%' : '', 
            }} />
            <Typography variant='subtitle' display='inline'> { item.title }</Typography>
            { (item.children && !forMobile) && <ExpandMore /> }
        </Button>
    )
}