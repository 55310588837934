import { Box, Button, Container, Unstable_Grid2 as Grid, Stack, Typography } from "@mui/material";
import pic from '../images/cover.png';

export default function PlaceholderPage() {
    return (
        <Container maxWidth={'lg'} >
            <Grid container spacing={2} sx={{ my: 4 }}>
                <Grid xs={12} sm={3} className="centered-container">
                    <Box sx={{ boxShadow: 8 }}>
                    <img src={pic} alt="SGFIN Whitepaper 2024-01" style={{ width: '100%' }} />
                    </Box>
                </Grid>
                <Grid xs={12} sm={9} sx={{ p: 6 }}>
                    <Stack spacing={4}>
                        <Box>
                            <Typography variant="subtitle2">
                                NEW Whitepaper:
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                Improving the Integrity of Sustainability Data: Reviewing Environmental Coverage of Sustainability Data Providers
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle2">
                                Accompanying Resource:
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                Environmental Data Hub: South-East Asia
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" gutterBottom>
                                Thank you for your interest in our whitepaper, which will be released soon. You can visit the link below to view it once it is available, or to explore other SGFIN whitepapers.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                In conjunction with the whitepaper, SGFIN will launch this Environmental Data Hub, offering insights into Southeast Asia's corporate sustainability reporting landscape and relevant sustainability data to facilitate investment and policy decisions.
                            </Typography>
                        </Box>
                        <Button className='pill_button secondary_button' href={'https://sgfin.nus.edu.sg/publications/whitepapers/'} target='_blank' rel="noreferrer">Read the paper</Button>
                    </Stack>
                    </Grid>
            </Grid>
        </Container>
    )
}