import ContentCopy from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export default function CopyToClipboard({ contentLabel, contentToCopy }) {
    const [isOpen, setIsOpen] = useState(false);
    const [popperMsg, setPopperMsg] = useState('');
    const handleOpenPopper = () => {
        setIsOpen(true);
        setTimeout(async () => {
            setIsOpen(false);
        }, 3000);
    }

    const copy = () => {
        setTimeout( async () => {
            await navigator.clipboard.writeText(contentToCopy)
            .then(() => {
                setPopperMsg('Copied!');
            })
            .catch( (error) => {
                setPopperMsg('Error: Unable to copy');
            });
            handleOpenPopper();
        }, 100);
    }
     
    return (
        <>
        <Button id="copy_btn" autoFocus sx={{ mx: 2 }} className='pill_button submit_button' onMouseDown={copy}><ContentCopy />&nbsp;Copy {contentLabel}</Button>
        <Popper className="hide-on-print" transition id={"popper_copy"} open={isOpen} anchorEl={document.getElementById('copy_btn')} placement={'top'} sx={{ zIndex: 1500 }}>
            {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Box 
                    sx={{ 
                        m: 1,
                        px: 1, 
                        bgcolor: popperMsg.includes('Error') ? '#FF7575' : '#1976d2',
                        color: 'white',
                        borderRadius: 8
                    }}
                >
                    <Typography variant='caption'>{popperMsg}</Typography>
                </Box>
            </Fade>
            )}
        </Popper>
        </>
    )
}