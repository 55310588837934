import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function FormSubsection({ children, title }) {
    return (
        <Box className="form-subsection">
            <Typography className="title-subsection">{title}</Typography>
            { children }
        </Box>
    )
}