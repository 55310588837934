import { InfoOutlined } from '@mui/icons-material';
import {
    Box,
    Popper,
    Typography
} from '@mui/material';
import { useState } from 'react';
import '../../../print.css';

export default function CarbonCreditsPopper() {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenPopper = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(true);
    }
    const handleClosePopper = () => {
        setIsOpen(false);
    }

    return (
        <>
        <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#42b868', boxSizing: 'border-box' , p: 2 }} className="hide-on-print">
            <Typography variant="h6" sx={{ fontSize: '1.1em', color: 'white' }}>
            Input Carbon Offset Credits:
            </Typography>
            <InfoOutlined style={{ color: 'white'}} className='infoIcon hide-on-print' onMouseEnter={handleOpenPopper} onMouseLeave={handleClosePopper} />
        </Box>
        <Popper id={"popper_res"} open={isOpen} anchorEl={anchorEl} placement={'left'} sx={{ width: '300px', zIndex: 1500 }}>
            <Box 
                sx={{ margin: 1, p: 2, 
                    borderRight: '4px solid var(--pri-yellow)',
                    bgcolor: 'var(--bg-yellow)' }}
            >
                <Typography variant="body2">
                    {/* TODO: update this */}
                    This calculator allows users to input the units of carbon offset for the event of interest and the amount is deducted upon final calculation of net (unoffset) carbon emissions from the event.
                </Typography>
            </Box>
        </Popper>
        </>
    )
}