import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

export default function StepperButtons({ incrementVal, decrementVal }) {
    const arrowStyle = {
        fontSize: '1.4em',
        cursor: 'pointer',
        '&:hover': { color: 'var(--nus-orange)'}
    }

    return (
        <Box className='centered-container'>
            <ExpandLess onClick={incrementVal} sx={arrowStyle}/>
            <ExpandMore onClick={decrementVal}  sx={arrowStyle} />
        </Box>
    )
}