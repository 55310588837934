import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppCardLinks from '../AppCardLinks';
import PageFooter from '../PageFooter';
import CustomGridContent from '../components/CustomGridContent';
import logoImg from '../images/NUS_SGFIN_logo.png';

export default function MainLandingPage() {
    return (
        <>
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '2em', minHeight: '60vh' }}>
                <img src={logoImg} style={{ maxHeight: '70px', maxWidth: '80vw', marginBottom: '2em' }} alt="NUS SGFIN logo" />
                <Typography variant='h4' sx={{ color: "var(--pri-blue)", marginBottom: 3 }}>
                    Tools & Resources
                </Typography>
                <CustomGridContent>
                    <AppCardLinks />
                </CustomGridContent>
            </Box>
        </Box>
        <PageFooter />
    </>
    )
}