import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Plot from "react-plotly.js";

// transport, accoms, onsite
const COLORS_DARK = ['#203864', '#800000', '#7F6000'];

export default function ResultPiePlot({ imageTag, outerRing, innerRing }) {
    const [plotData, setPlotData] = useState([]);
    const [data, setData] = useState([]);
    const [originalColors, setOriginalColors] = useState([]);


    const setUpDataArray = (dataObj, isChild) => {
        let dataArray = [];
        Object.entries(dataObj).forEach( ([key, val], idx) => {
            var data_id = isChild ? key.substring(3,) : key.split("_")[1];
            dataArray.push(
                {
                    ids: data_id,
                    parents: isChild ? key.split("_")[1] : 'Total Emissions',
                    labels: isChild ? val.label : val.label.split(": ")[1],
                    values: ( val.value[1] ? val.value[0]+val.value[1] : val.value[0] ),
                    colors: (
                        data_id.includes("onsite") ? COLORS_DARK[2] :
                        data_id.includes("hotel") ? COLORS_DARK[1] :
                        COLORS_DARK[0]
                    )
                }
            )
        });
        return dataArray
    }
    
    const unpack = (rows, key) => {
        return rows.map(function(row) {return row[key]})
    }

    useEffect(() => {
        var defaultParent = [{
            ids: 'Total Emissions',
            parents: null,
            labels: 'Total Emissions',
            values: null,
        }];
        var setupPlotData = [...defaultParent, ...setUpDataArray(innerRing, 0), ...setUpDataArray(outerRing, 1)]
        setPlotData(setupPlotData)
        setOriginalColors(unpack(setupPlotData, 'colors'));
    },[outerRing, innerRing]);

    useEffect(() => {
        var setupData = [{
            type: 'sunburst',
            maxdepth: 2,
            ids: unpack(plotData, 'ids'),
            labels: unpack(plotData, 'labels'),
            parents: unpack(plotData, 'parents'),
            values: unpack(plotData, 'values'),
            texttemplate: '%{label}<br />%{percentEntry}',
            textposition: 'outside',
            insidetextorientation: 'radial',
            branchvalues: 'total',
            hovertemplate: ['%{label}', ...Array(11).fill('%{label}<br />%{value:.2f}<br />%{percentParent:.2%} of %{parent}<extra></extra>')],
            hoverlabel: {
                bgcolor: unpack(plotData, 'colors')
            },
            marker: {
                colors: unpack(plotData, 'colors')
            }
        }];
        setData(setupData);
    }, [plotData])

      // Event handler for hover events
    const handleHover = (event) => {
        const { points } = event;
        const hoveredIndex = event.points[0].pointNumber
        const hoveredColor = originalColors[hoveredIndex];
        const updatedColors = Array(data[0].labels.length).fill('#D3D3D3'); // Set initial color to grey for all sectors
        if (points) updatedColors[hoveredIndex] = hoveredColor
        const updatedData = [{ ...data[0], marker: { ...data[0].marker, colors: updatedColors } }];
        setData(updatedData);
    };
    const handleUnhover = () => {
        const updatedData = [{ ...data[0], marker: { ...data[0].marker, colors: originalColors } }];
        setData(updatedData);
    }

return (
    <Box id={imageTag} className="remove-padding-print" sx={{ marginY: 1, display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Plot 
            data={data}
            layout={{
                margin: {l: 0, r: 0, b: 0, t: 0 },
                height: 300,
                width: 600,
                font: {
                    family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                },
                annotations: Object.entries(plotData).map(([key, val], i) => {
                    return (
                        {
                            x: 1,
                            y: 1-(i/12),
                            font: {
                                color: (
                                    val.ids.includes("onsite") ? COLORS_DARK[2] :
                                    val.ids.includes("hotel") ? COLORS_DARK[1] :
                                    COLORS_DARK[0]
                                )
                            },
                            xref: 'paper',
                            yref: 'paper',
                            text: [1,2,3].includes(i) ? val.labels + ' &#x25A0;': '',
                            textangle: 0,
                            xanchor: 'right',
                            showarrow: false,
                        }
                    )
                }),
            }}
            config={{
                responsive: true,
                displayModeBar: false,
            }}
            onHover={handleHover}
            onUnhover={handleUnhover}
        />
    </Box>
);
}
