import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { updateSwitchableData } from '../helpers/FormHelper';
import { OverallDataContext } from '../pages/CalculatorPage';
import FormSubsection from './FormSubsection';
import FormInputField from './inputs/FormInputField';

export default function SwitchableSubsection({ tabName, section_tag, subsection, updateData }) {
    const [overallData, setData] = useContext(OverallDataContext).allData;
    const [counterData, setCounterData] = useContext(OverallDataContext).counterData;

    const switch_id = `${section_tag}_switch`;
    const switchData = {
        'method1' : {
            'fieldsId': 0,
            'inactiveSection': 'b',
            'isOn': true,
        },
        'method2' : {
            'fieldsId': 1,
            'inactiveSection': 'a',
            'isOn': false,
        }
    };
    const [currSwitchData, setSwitchData] = useState(switchData.method1);
    const handleSwitchChange = event => {
        const newSwitchData = ( currSwitchData.isOn === true ) ? switchData.method2 : switchData.method1;
        setSwitchData(newSwitchData);
        updateSwitchableData(tabName, newSwitchData.inactiveSection, section_tag, overallData, setData, counterData, setCounterData, subsection.affectedCounters);
    }

    useEffect(() => {
        setData(d => ({
            ...d,
            [tabName]: {
                ...d[tabName],
                [`${section_tag}_${section_tag}_isMethod1`]: (currSwitchData.fieldsId==0) // NOTE: section_tag is doubled because backend script removes first 3 characters
            }
        }))
    }, [currSwitchData])

    return (
        <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'items', flexDirection: 'row' }}>
            <Switch 
                id={switch_id} 
                checked={currSwitchData.isOn} onChange={handleSwitchChange} 
                sx={{ transform: 'rotate(-0.25turn)' }}
                inputProps={{ 'aria-label': 'controlled' }} 
            />
            <Box>
                <Typography variant={currSwitchData.isOn ? 'body1' : 'subtitle2'} className={currSwitchData.isOn ? 'title-subsection' : 'secondary-title'} >{subsection.title}</Typography>
                <Typography variant={currSwitchData.isOn ? 'subtitle2' : 'body1'} className={currSwitchData.isOn ? 'secondary-title' : 'title-subsection' } >{subsection.alt_title}</Typography>
            </Box>
        </Box>
        <FormSubsection>
            {
                subsection.fields[currSwitchData.fieldsId].map((field, f_index) => {
                    return (
                        <FormInputField
                            field={field}
                            tabName={tabName}
                            key={field.id_name + f_index}
                            onChangeHandler={updateData}
                        />
                    );
                })
            }
        </FormSubsection>
        </>
    )
}