import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import InputOutlined from '@mui/icons-material/InputOutlined';
import Language from '@mui/icons-material/Language';
import PieChart from '@mui/icons-material/PieChart';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import ModalBase from '../../../common/components/cards/ModalBase';
import IconButton from '../nav/IconButton';

const ModalSubheading = styled(Typography) ({
    fontWeight: '700',
    marginTop: '16px'
})

export default function CalcModal({ defaultOpen }) {
    const [open, setOpen] = useState(defaultOpen);
    const handleOpen = () => setOpen(true);

    return (
        <ModalBase 
            title="SGFIN MICE Carbon Calculator"
            open={open}
            setOpen={setOpen}
            opener={
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton icon={<HelpIcon />} label="How to use" onClickAction={handleOpen}/>
                </Box>
            }
            >
            <Typography variant='body2'>Before you begin, please take a moment to read the notes below to understand how to use this calculator.</Typography>
            
            <ModalSubheading><Language fontSize='inherit' /> Required Inputs</ModalSubheading>
            <Typography variant='body2'>
                Please prioritise Global Inputs including number of attendees (physical and virtual, local and international) and event period to ensure the accurcy of the results.
            </Typography>

            <ModalSubheading><InputOutlined fontSize='inherit' /> Form Inputs</ModalSubheading>
            <Typography variant='body2'>
                Fill in all possible known values as requested. You may choose to fill in precise values if they are readily available, otherwise you can choose an appropriate range from the dropdown list for each input field.
                <br/>
                <br/>
                For quantities with a value of 0, please explicity enter 0, otherwise default baseline values will be taken for the calculation which can affect the accuracy of the carbon footprint measured for your specific MICE event.
            </Typography>

            <ModalSubheading><PieChart fontSize='inherit' /> Results Provided</ModalSubheading>
            <Typography variant='body2'>
                This calculator outputs the total carbon footprint of this event in tonnes of CO<sub>2</sub>e per capita carbon footprint per day, benchmarked against that of a typical MICE event in the United States.
                <br/>
                <br/>
                You will also be able to view the breakdown of the carbon footprint contributed by each major source of carbon footprint (On-site Activities, Transportation and Accommodations).
            </Typography>
        </ModalBase>
    );
}