import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState } from 'react';
import { OverallDataContext } from '../pages/CalculatorPage';
import CounterPill from "./CounterPill";

export default function FieldCounter({ counterId }) {
    const counterData = useContext(OverallDataContext).counterData[0];

    const [value_a, setValueA] = useState();
    const [label_a, setLabelA] = useState();
    const [value_b, setValueB] = useState();
    const [label_b, setLabelB] = useState();
    const [isAlwaysShown, setShow] = useState(false);

    useEffect(() => {
        setShow(counterData[counterId]?.isAlwaysShown ?? false);
        setValueA(counterData[counterId]?.value_a ?? 0);
        setLabelA((counterData[counterId]?.label_a ?? [""]).join(" + "));
        setValueB(counterData[counterId]?.value_b ?? 1);
        setLabelB((counterData[counterId]?.label_b ?? [""]).join(" + "));
    }, [counterData, counterId]);
    
    return (
        <>
            {
                (isAlwaysShown || value_a > value_b ) &&
                <Grid container className="form-field" sx={{ marginTop: 2 }} >
                    <Grid xs={12} sm={'auto'}>
                        <CounterPill value_a={value_a} value_b={value_b} />
                    </Grid>
                    <Grid xs={12} sm={'auto'}>
                        <Box sx={{ padding: 1 }}>
                            <Typography variant='subtitle2' className='secondary-title'>
                                {label_a} {label_b && '/'} {label_b}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            } 
        </>
    )
}