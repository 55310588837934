import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import PageFooter from '../../common/PageFooter';
import InfoCard from '../../common/components/cards/InfoCard';
import NavSidebar from '../../common/components/nav/NavSidebar';
import FetchCalcModal from '../components/popups/FetchCalcModal';
import logoImg from '../images/NUS_SGFIN_logo.png';
import cover from '../images/SGFIN_WHITEPAPER_03_cover.png';
import pic from '../images/mbs1.jpg';
import pic2 from '../images/mbs1_labelled.jpg';

const Subheader = styled(Typography) ({
    fontSize: '1.2em',
    fontWeight: '700',
})

function H5Subheader({ children, ...props }) {
    return (
        <Subheader variant='h5' {...props}>{children}</Subheader>
    )
}

export default function LandingPage() {
    const navigate = useNavigate();
    return (
        <>
        <AppBar position="sticky" sx={{ backgroundColor: 'white', boxShadow: 'none' }} >
			<Container maxWidth="xl">
				<Toolbar disableGutters>
                    <NavSidebar />
                    {/* <Box sx={{ my: 4, mx: 1 }} > */}
                    <Box sx={{ width: { xs: '100%', sm: '50%', md: '30%' }, m: 4 }} >
                        <a href="/">
                            <img src={logoImg} style={{  width: '100%', marginBottom: 4 }} alt="NUS SGFIN logo" />
                        </a>     
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Box sx={{ minHeight: '60vh' }}>
			<Container maxWidth="xl">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingY: '2em' }}>
                    <Grid container spacing={4}>
                        <Grid xs={12} md={4}>
                            <Box sx={{ borderRadius: '8px', aspectRatio: { xs: 1.5, md: 1 }, background: `var(--bg-light) url(${pic}) no-repeat`, backgroundPosition: { xs: 'top', md: 'center' }, backgroundSize: 'cover' }}>
                            <Box sx={{ borderRadius: '8px', aspectRatio: { xs: 1.5, md: 1 }, background: `var(--bg-light) url(${pic2}) no-repeat`, backgroundPosition: { xs: 'top', md: 'center' }, backgroundSize: 'cover', transition: 'opacity 0.4s', opacity: 0, '&:hover' : { opacity: 100 } }}> 
                            </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={8} className="vertically-centered">
                            <Typography variant='h3'>
                                MICE Carbon Calculator
                            </Typography>
                            <Typography variant='body1' color='text.secondary' marginY="2em">
                            This toolkit is designed to help event organisers, planners, and regulators calculate and track the carbon footprint for meetings, incentives, conventions, and exhibitions (MICE) in Singapore. 
                            It identifies major sources of carbon emissions throughout an event and estimates greenhouse gas (GHG) emissions using emission factors from credible data sources, including statistics from the Singapore Tourism Board (STB) and other regulatory agencies.
                            </Typography>
                            <Stack direction={{ xs: "column", sm: "row"}} spacing={1}>
                                <Button className='pill_button submit_button' onClick={() => navigate(process.env.REACT_APP_CC_ROOT +'/calculator')}>Calculate event carbon footprint</Button>
                                <FetchCalcModal />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Container maxWidth="xl" sx={{ paddingY: '4em' }}>
                <Grid container spacing={4}>
                    <Grid xs={12} md={5}>
                        <InfoCard
                            title={"What are the three major components?"} 
                            description={
                                <ul>
                                    <li>Transportation</li>
                                    <li>Hotel Accommodation</li>
                                    <li>On-site Activities</li>
                                </ul>
                            } 
                            shadowColor='#487881'
                        />
                    </Grid>
                    <Grid xs={12} md={7}>
                        <InfoCard
                            title={"What are the methodologies used?"} 
                            description={
                                <ul>
                                    <li>
                                    Net Zero Carbon Events (NZCE) framework for event carbon footprint
                                    </li>
                                    <li>
                                    Hotel Carbon Measurement Initiative (HCMI) framework for emissions from accommodation
                                    </li>
                                    <li>
                                    International Civil Aviation Organisation (ICAO) carbon calculator for commercial air travel
                                    </li>
                                </ul>
                            } 
                            shadowColor='#487881'
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <InfoCard
                            title={"Who is this calculator designed for?"} 
                            description={
                                <ul>
                                    <li>
                                    Singapore MICE event organisers
                                    </li>
                                    <li>
                                    Regulators, analysts and researchers
                                    </li>
                                </ul>
                            } 
                            shadowColor='#487881'
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <InfoCard
                            title={"What will the results tell you?"} 
                            description={
                                <ul>
                                    <li>
                                    Total carbon footprint in tonnes of CO&#x2082;e (Carbon Dioxide equivalent)
                                    </li>
                                    <li>
                                    Carbon emission intensity (per capita carbon footprint per day) of the event with benchmarks
                                    </li>
                                </ul>
                            } 
                            shadowColor='#487881'
                        />
                    </Grid>
                    <Grid xs={12} md={7}>
                        <iframe style={{ width: '100%', aspectRatio: '16/9', borderRadius: '24px', border: '1px solid #cecece' }} src="https://www.youtube.com/embed/j9tFp87diRQ" title="SGFIN Mice Carbon Calculator" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </Grid>
                    <Grid xs={12} md={5}>
                        <InfoCard
                            title={"Calculate your event's carbon footprint now"} 
                            description={"Watch the demo!"} 
                            icon={"octicon:arrow-left-16"}
                            hasHoverEffects={false}
                            additionalStyles={{ height: '100%', boxSizing: 'border-box' }}
                        />
                    </Grid>
                </Grid>
            </Container>
            
            <Box className='light-background' >
            <Container maxWidth="xl" sx={{ padding: '4em 2em' }} >
                <Grid container spacing={6}>
                    <Grid sm={12} md={9} className='vertically-centered'>
                        <Box
                            className="hide-on-print" 
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                px: 2,
                                py: 0.5,
                                borderRadius: '2em',
                                backgroundColor: 'var(--btn-green)'
                            }}
                        >
                            <Typography variant='subtitle2' sx={{ fontSize: '0.7em', color: "white" }}>
                            NEW!
                            </Typography> 
                        </Box>

                        <Typography variant='h6'>
                            SGFIN Whitepaper Series #3
                        </Typography>
                        <Typography variant='h5'>
                            A Guide to Sustainable Events: Singapore MICE Carbon Calculator
                        </Typography>
                        <Typography variant='body1' color='text.secondary' marginY="2em">
                            SGFIN has now launched its whitepaper on this MICE carbon calculator, which will explain the methodology behind the calculator in greater detail. 
                            The paper will describe how the principles of the Net Zero Carbon Events (NZCE) framework was applied to the calculation methodology, and includes full disclosure of the various data sources used, along with the specific MICE data categories to which they were applied.
                        </Typography>
                        <Box>
                            <Button className='pill_button secondary_button' href={'https://sgfin.nus.edu.sg/publications/whitepapers/'} target='_blank' rel="noreferrer">Read the paper</Button>
                        </Box>
                    </Grid>
                    <Grid sm={12} md={3}>
                        <Box sx={{ boxShadow: 8, backgroundColor: 'white' }}>
                            <img src={cover} alt="SGFIN Whitepaper 2024" style={{ width: '100%' }} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </Box>
        <PageFooter />
    </>
    )
}