import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { OverallDataContext } from '../../pages/CalculatorPage';

export default function InputNumField({ id_name, tabName, checkInputValue, isRequired, options=[], errorMsg, setFocus, isReadOnly }) {
    const overallData = useContext(OverallDataContext).allData[0];
    const tempData = useContext(OverallDataContext).tempData;
    const [fieldValue, setFieldValue] = useState('');
    const [tempPlaceholder, setPlaceholder] = useState('');

    const handleOnChange = (field_id, field_value) => {
        checkInputValue(field_id, field_value);
        setFieldValue(field_value);
    }
    useEffect(() => {
        let stateVal = null;
        try { stateVal = overallData[tabName][id_name].toString(); }
        catch (e) {}
        if (stateVal) {
            if (stateVal.includes('-') || isNaN(parseFloat(stateVal))) setFieldValue(stateVal);
            else setFieldValue(parseFloat(stateVal).toLocaleString('en-US', {maximumFractionDigits: 2, useGrouping: false}))
        } else setFieldValue('');
    }, [overallData]);

    useEffect(() => {
        let stateVal = null;
        try { stateVal = tempData[tabName][id_name]; }
        catch (e) {}
        if (stateVal) setPlaceholder(stateVal.toString());
        else setPlaceholder('');
    }, []);

    return (
        <Autocomplete
            id={ id_name }
            freeSolo
            fullWidth
            autoSelect
            openOnFocus
            options={options}
            getOptionLabel={(option) => ( option !== undefined ? (
                (option.value) ? 
                ( option.label ? `${option.value} (${option.label})` :  option.value ) :
                option.label
            ) : '' ) || '' }
            forcePopupIcon={(options != null && options.length > 0)}
            renderInput={(params) => 
                <TextField {...params}
                    name={ "tf"+id_name } id={ "tf"+id_name }
                    type="text"
                    placeholder={tempPlaceholder}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    required={isRequired}
                    aria-labelledby={id_name}
                    error={(errorMsg !== '')}
                    helperText={errorMsg ? errorMsg : ''}
                    sx={{ paddingY: 0 }}
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                              if (e.key === 'Enter') {
                                e.stopPropagation();
                              }
                        },
                    }}
                />
            }
            // for controlled states
            value={fieldValue}
            inputValue={fieldValue}
            onChange={(event, option_selected, reason) => {
                if (reason === 'selectOption') handleOnChange(id_name, option_selected.value);
            }}
            onInputChange={(event, input_value, reason) => {
                if (reason === 'clear') handleOnChange(id_name, "");
                if (reason === 'input') handleOnChange(id_name, input_value);
            }}
            size="small"
            readOnly={isReadOnly}
            disabled={isReadOnly}
        />
    )
}