export const travellersRelationships = {
    inputs: ["g1_international_attendees", 't1_travellers_africa-a', 't1_travellers_americas-a', 't1_travellers_europe-a', 't1_travellers_greater_china-a', 't1_travellers_north_asia-a', 't1_travellers_oceania-a', 't1_travellers_south_asia-a', 't1_travellers_southeast_asia-a', 't1_travellers_west_asia-a' ],
    grouping: [
        ["g1_international_attendees"],
        [ 't1_travellers_southeast_asia-a', 't1_travellers_greater_china-a', 't1_travellers_north_asia-a', 't1_travellers_south_asia-a', 't1_travellers_west_asia-a', 't1_travellers_americas-a', 't1_travellers_europe-a', 't1_travellers_oceania-a', 't1_travellers_africa-a' ]
    ]
}

export const counterRelationships = {
    "travellerRs": {
        isAlwaysShown: true,
        tabName: "transport",
        inputs: ["g1_international_attendees", 't1_travellers_africa-a', 't1_travellers_americas-a', 't1_travellers_europe-a', 't1_travellers_greater_china-a', 't1_travellers_north_asia-a', 't1_travellers_oceania-a', 't1_travellers_south_asia-a', 't1_travellers_southeast_asia-a', 't1_travellers_west_asia-a', 't1_travellers_others-a' ],
        group1: {
            tab: "Transportation",
            label: ["Sum of Travellers from different regions"],
            inputs: [ 't1_travellers_southeast_asia-a', 't1_travellers_greater_china-a', 't1_travellers_north_asia-a', 't1_travellers_south_asia-a', 't1_travellers_west_asia-a', 't1_travellers_americas-a', 't1_travellers_europe-a', 't1_travellers_oceania-a', 't1_travellers_africa-a', 't1_travellers_others-a' ]
        },
        group2: {
            tab: "Global",
            label: ["Total Number of International Attendees"],
            inputs: ["g1_international_attendees"],
        },
    },
    // "mealsRs": {
    //     isAlwaysShown: true,
    //     tabName: "onsite",
    //     inputs: [ "o2_meals_per_day", "o2_teas_per_day", "g1_total_meals_per_day" ],
    //     group1: {
    //         tab: "Onsite Activities",
    //         label: ["Meals per Day", "Tea/Coffee Breaks per Day"],
    //         inputs: [ "o2_meals_per_day", "o2_teas_per_day" ]
    //     },
    //     group2: {
    //         tab: "Global",
    //         label: ["Total Number of Meals per Day On-site"],
    //         inputs: ["g1_total_meals_per_day"],
    //     },
    // },
    "vegetarianRs": {
        isAlwaysShown: false,
        tabName: "onsite",
        inputs: [ "o2_num_vegetarians", "g1_physical_attendees" ],
        group1: {
            tab: "Onsite Activities",
            label: ["Number of Vegetarians"],
            inputs: [ "o2_num_vegetarians" ]
        },
        group2: {
            tab: "Global",
            label: ["Total Number of Physical Attendees"],
            inputs: ["g1_physical_attendees"],
        },
    },
    "frac_tripsRs": {
        isAlwaysShown: true,
        tabName: "transport",
        inputs: [ "t1_fraction_economy_class-a", "t1_fraction_premium_class-a", "t1_fraction_private_jet-a" ],
        group1: {
            tab: "Transportation",
            label: ["Percentage Breakdown of Flight Categories"],
            inputs: [ "t1_fraction_economy_class-a", "t1_fraction_premium_class-a", "t1_fraction_private_jet-a" ],
        },
    },
    "frac_roomsRs": {
        isAlwaysShown: true,
        tabName: "hotel",
        inputs: [ "h1_frac_three_star-b", "h1_frac_four_star-b", "h1_frac_five_star-b" ],
        group1: {
            tab: "Accommodations",
            label: ["Percentage Breakdown of Room Classes"],
            inputs: [ "h1_frac_three_star-b", "h1_frac_four_star-b", "h1_frac_five_star-b" ],
        },
    },
}

export const FormStructure = {
    // ========================== STRUCTURE REFERENCE ==========================
    // tabName : {
    //     tabTitle,
    //     tabDescription,
    //     sections: [
    //         {
    //             title,
    //             tag,
    //             isOpen,
    //             subsections: [
    //                 {
    //                     title,
    //                     isSwitchable,
    //                     fields: [
    //                          {
    //                             id_name,
    //                             label,
    //                             caption,
    //                             isSelect,
    //                             isStepper,
    //                             maxValue,
    //                             default_val,
    //                             options: [
    //                                 {
    //                                     label,
    //                                     value
    //                                 }
    //                             ],
    //                         },
    //                     ]
    //                 },
    //             ]
    //         },
    //     ]
    // },
    "global" : {
        tabTitle: "How to use this calculator:",
        tabDescription: "Please fill in the inputs (both global and under three sources of carbon footprint) where data is available, but please prioritise Global Inputs including number of attendees (physical and virtual, local and international) and event period to ensure the accurcy of the results.",
        sections: [
            {
                title: "Global Assumptions",
                tag: "g1",
                isOpen: true,
                subsections: [
                    {
                        fields: [
                            {
                                id_name: "g1_physical_attendees",
                                label: "Total Number of Physical Attendees",
                                caption: "The number of people attending the MICE event in person.",
                                description: "Input the number of people estimated to attend the event in person. This includes both local and international event participants. The number of physical attendees largely affects the total carbon footprint but not per capita footprint. The choice of event venue and on-site hardware and resources should also take into account the number of physical attendees.",
                                units: "pax",
                                isRequired: true,
                                isSelect: false,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-1000"
                                    },
                                    {
                                        value: "1000-5000"
                                    },
                                ],
                            },
                            {
                                id_name: "g1_virtual_attendees",
                                label: "Total Number of Virtual Attendees",
                                caption: "The number of people attending the MICE event virtually via video conference.",
                                description: "Input the number of people estimated to attend the event virtually. Simply input 0 if the event does not support virtual attendance. Footprint from transportation and accommodation does not apply to virtual participants as there is no incremental carbon emissions incurred from video conference except emissions from video conference device usage.",
                                units: "pax",
                                isRequired: true,
                                isSelect: false,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-1000"
                                    },
                                    {
                                        value: "1000-5000"
                                    },
                                ],
                            },
                            {
                                id_name: "g1_international_attendees",
                                label: "Total Number of International Attendees",
                                caption: "The number of international attendees among physical attendees.",
                                description: "Input the number of international attendees among physical attendees at the event. International attendees incur additional carbon emissions from air travel and hotel accommodation, which are a significant component in total event carbon footprint. The number of international attendees should be no greater than the total number of physical attendees.",
                                units: "pax",
                                isRequired: true,
                                isSelect: false,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-1000"
                                    },
                                    {
                                        value: "1000-5000"
                                    },
                                ],
                            },
                            {
                                id_name: "g1_hours_per_day",
                                label: "Event Hours per Day",
                                caption: "The average duration of the event per day.",
                                description: "Input the average daily event duration in hours per day. This number will be used as the effective duration the on-site hardware system is active each day. Longer event duration means higher emissions from electricity usage and on-site activities. A typical event may take 6 ~ 16 hours each day.",
                                units: "hours",
                                maxValue: 24,
                                isRequired: true,
                                isSelect: false,
                                options: [
                                    {
                                        value: "1"
                                    },
                                    {
                                        value: "2"
                                    },
                                    {
                                        value: "4"
                                    },
                                    {
                                        value: "8"
                                    },
                                    {
                                        value: "12"
                                    },
                                ],
                            },
                            {
                                id_name: "g1_event_days",
                                label: "Number of Event Days",
                                caption: "The event duration in days.",
                                description: "Input the event duration in days. This number will affect the estimation of carbon footprint from local transport and on-site activities. The total event hours is estimated to be the product of event hours per day and the number of event days.",
                                units: "days",
                                isRequired: true,
                                isSelect: false,
                                options: [
                                    {
                                        value: "1"
                                    },
                                    {
                                        value: "2"
                                    },
                                    {
                                        value: "3"
                                    },
                                    {
                                        value: "4"
                                    },
                                    {
                                        value: "5"
                                    },
                                    {
                                        value: "6"
                                    },
                                    {
                                        value: "7"
                                    },
                                ],
                            },
                            {
                                id_name: "g1_setting",
                                label: "Event Setting",
                                caption: "The MICE event setting. Note that different rooms have different capacities.",
                                description: "Select an event setting from the dropdown menu. A typical conference venue is designed with functions of exhibition, theatre, classroom, cluster and banquet. Depending on the event setting, the event rooms may have different capacities with different lighting, visual and audio systems, which affect the electricity usage that leads to different rates of carbon emissions.",
                                default_val: "exhibition",
                                isSelect: true,
                                options: [
                                    {
                                        value: "Exhibition",
                                    },
                                    {
                                        value: "Theatre",
                                    },
                                    {
                                        value: "Classroom",
                                    },
                                    {
                                        value: "Cluster",
                                    },
                                    {
                                        value: "Banquet",
                                    },
                                ],
                            },
                            {
                                id_name: "g1_attendance_period_is_throughout",
                                label: "Do All Attendees Attend Throughout Event Period?",
                                // caption: "",
                                description: "If attendees stay only for one day during the event period, the average attendees per day will be divided by the number of event days.",
                                default_val: "All Attendees Stay Throughout Event Period",
                                isRequired: true,
                                isSelect: true,
                                options: [
                                    {
                                        value: 1,
                                        label: "All Attendees Stay Throughout Event Period",
                                    },
                                    {
                                        value: 0,
                                        label: "All Attendees Only Stay for One-Day Event",
                                    },
                                ],
                            },
                            {
                                id_name: "g1_event_meals_provided",
                                label: "Are Meals Provided for the Event?",
                                // caption: "Including breakfast/lunch/dinner meals AND tea/coffee breaks.",
                                // description: "Input the number of meals provided, including breakfast, lunch and dinner meals and tea/coffee breaks. This number affects the estimated carbon footprint from food and beverages. Usually, the number of meals catered per day depends on the event duration and a full-day event may entail five catered meals (3 meals and 2 tea/coffee breaks).",
                                isRequired: true,
                                isSelect: true,
                                options: [
                                    {
                                        value: 1,
                                        label: "Yes"
                                    },
                                    {
                                        value: 0,
                                        label: "No"
                                    },
                                ],
                            },
                        ]
                    }
                ]
            },
        ]
    },
    "transport" : {
        tabTitle: "Transportation",
        tabDescription: "",
        sections: [
            {
                title: "International (Air Travel)",
                tag: "t1",
                subsections: [
                    {
                        title: "Method 1: ICAO Carbon Emission Calculator Estimation",
                        alt_title: "Method 2: Direct Estimation",
                        isSwitchable: true,
                        affectedCounters: {
                            "-a": ["travellerRs", "frac_tripsRs"],
                        },
                        fields: [
                            [
                                {
                                    id_name: "t1_fraction_economy_class-a",
                                    label: "Percentage of Economy-Class Trips",
                                    // caption: "Proportion of trips to be booked in the Economy class. The remainder will be automatically allocated to the proportion of trips under Business class.",
                                    description: "Input the fraction of economy-class flights as opposed to premium-class flights among international attendees. ICAO carbon footprint assumes that premium-class flights incur twice as much carbon emissions as their economy-class equivalents do.",
                                    units: "%",
                                    isSelect: false,
                                    default_val: 80,
                                    options: [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ],
                                    counterId: "frac_tripsRs",
                                },
                                {
                                    id_name: "t1_fraction_premium_class-a",
                                    label: "Percentage of Premium-Class Trips",
                                    description: "Input the fraction of premium-class flights.",
                                    units: "%",
                                    isSelect: false,
                                    default_val: 20,
                                    options: [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_fraction_private_jet-a",
                                    label: "Percentage of Private Jet Trips",
                                    description: "Input the fraction of private jet flights.",
                                    units: "%",
                                    default_val: 0,
                                    isSelect: false,
                                    options: [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_southeast_asia-a",
                                    label: "Travellers from Southeast Asia",
                                    caption: "Including Indonesia, Malaysia, Philippines, Thailand, Vietnam, etc.",
                                    description: "Input the number of international travellers from Southeast Asia (Indonesia, Malaysia, Philippines, Thailand, Vietnam, etc.)",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 246,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                    counterId: "travellerRs",
                                },
                                {
                                    id_name: "t1_travellers_greater_china-a",
                                    label: "Travellers from East Asia",
                                    caption: "Including China, Hong Kong SAR, Taiwan, etc.",
                                    description: "Input the number of international travellers from East Asia (China, Hong Kong SAR, Taiwan, etc.).",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 140,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_north_asia-a",
                                    label: "Travellers from North Asia",
                                    caption: "Including Japan, South Korea, etc.",
                                    description: "Input the number of international travellers from North Asia (Japan, South Korea, etc.).",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 112,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_south_asia-a",
                                    label: "Travellers from South Asia",
                                    caption: "Including India, Bangladesh, etc.",
                                    description: "Input the number of international travellers from South Asia (India, Bangladesh, etc.).",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 101,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_west_asia-a",
                                    label: "Travellers from West Asia",
                                    caption: "Including UAE, Saudi Arabia, Isreal, etc.",
                                    description: "Input the number of international travellers from West Asia (UAE, Saudi Arabia, Israel, etc.).",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 6,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_americas-a",
                                    label: "Travellers from Americas",
                                    caption: "Including US, Canada, etc.",
                                    description: "Input the number of international travellers from Americas (US, Canada, etc.).",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 57,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_europe-a",
                                    label: "Travellers from Europe",
                                    caption: "Including United Kingdom, Germany, France, Russia, Switzerland, etc.",
                                    description: "Input the number of international travellers from Europe (United Kingdom, Germany, France, Russia, Switzerland, etc.",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 97,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_oceania-a",
                                    label: "Travellers from Oceania",
                                    caption: "Including Australia, New Zealand, etc.",
                                    description: "Input the number of international travellers from Oceania (Australia, New Zealand, etc.).",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 39,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_africa-a",
                                    label: "Travellers from Africa",
                                    caption: "Including South Africa, Egypt, Mauritius, etc.",
                                    description: "Input the number of international travellers from Africa (South Africa, Egypt, Mauritius, etc.)",
                                    units: "pax",
                                    isSelect: false,
                                    default_val: 2,
                                    options: [
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-300"
                                        },
                                        {
                                            value: "300-500"
                                        },
                                        {
                                            value: "500-800"
                                        },
                                        {
                                            value: "800-1000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "t1_travellers_others-a",
                                    label: "Travellers from Other Regions",
                                    description: "Input the number of international travellers not from any region listed above.",
                                    caption: "Travellers from any region NOT listed above",
                                    units: "pax",
                                    // isReadOnly: true, 
                                    isSelect: false,
                                    default_val: 0,
                                },
                            ],
                            [
                                {
                                    id_name: "t1_air_emission-b",
                                    label: "Total International Travel Emissions",
                                    description: "Input the total carbon footprint from air travel in kg CO\u2082e. This estimation is often provided by airlines. Please consider one-way/two-way trips and cabin classes and aggregate the carbon emissions from air trips of all international attendees.",
                                    units: "kg CO\u2082e",
                                    isSelect: false,
                                    default_val: 421932.11,
                                }
                            ],
                        ]
                    }
                ]
            },
            {
                title: "Local (Land Transport)",
                tag: "t2",
                subsections: [
                    {
                        fields: [
                            {
                                id_name: "t2_local_residence",
                                label: "Residing Area for International Attendees",
                                caption: "District for accommodations of international attendees.",
                                description: "Select the district in Singapore for accommodations of international attendees from the dropdown menu. Carbon footprint from local transport during the event period depends on the distance of land travel from the local residence of physical attendees to the event venue. As the event venue is often chosen in the central district, the choice of residential area for international attendees largely affects the carbon emissions from local travel. Event organisers may choose one or a few close locations to accommodate the international attendees.",
                                default_val: "Central",
                                isSelect: true,
                                options: [
                                    {
                                        value: "Central"
                                    },
                                    {
                                        value: "East"
                                    },
                                    {
                                        value: "Northeast"
                                    },
                                    {
                                        value: "North"
                                    },
                                    {
                                        value: "West"
                                    },
                                ],
                            }
                        ]
                    }
                ]
            },
            {
                title: "Freight",
                tag: "t3",
                subsections: [
                    {
                        fields: [
                            {
                                id_name: "t3_freight_mass_by_air",
                                label: "Mass Transported by Air",
                                caption: "Mass of goods transported to event by air freight",
                                // description: "TODO",
                                units: "kg",
                                default_val: 16,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-800"
                                    },
                                    {
                                        value: "800-1000"
                                    },
                                ],
                            },
                            {
                                id_name: "t3_freight_mass_by_sea",
                                label: "Mass Transported by Sea",
                                caption: "Mass of goods transported to event by sea shipping",
                                // description: "TODO",
                                units: "kg",
                                default_val: 5000,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-800"
                                    },
                                    {
                                        value: "800-1000"
                                    },
                                ],
                            },
                            {
                                id_name: "t3_freight_mass_by_road",
                                label: "Mass Transported by Road",
                                caption: "Mass of goods transported to event by local road transport",
                                // description: "TODO",
                                units: "kg",
                                default_val: 100000,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-800"
                                    },
                                    {
                                        value: "800-1000"
                                    },
                                ],
                            },
                            
                        ]
                    }
                ]
            }
        ]
    },
    "hotel": {
        tabTitle: "Accommodations",
        tabDescription: "",
        sections: [
            {
                title: "Hotel Carbon Measurement",
                tag: "h1",
                isOpen: false,
                subsections: [
                    {
                        title: "",
                        isSwitchable: false,
                        fields: [
                            {
                                id_name: "h1_hotel_nights",
                                label: "Number of Hotel Accommodation Nights",
                                caption: "The number of nights for the hotel stay.",
                                description: "Input the hotel accommodation provided in nights. This number will affect the carbon footprint from hotel accommodation only. The accommodation period should match the number of event days in a typical accommodation plan.",
                                units: "nights",
                                isRequired: true,
                                isSelect: false,
                                options: [
                                    {
                                        value: "1"
                                    },
                                    {
                                        value: "2"
                                    },
                                    {
                                        value: "3"
                                    },
                                    {
                                        value: "4"
                                    },
                                    {
                                        value: "5"
                                    },
                                    {
                                        value: "6"
                                    },
                                    {
                                        value: "7"
                                    },
                                ],
                            },
                            {
                                id_name: "h1_cf_method",
                                label: "Carbon Footprint Method",
                                description: "Select the method to estimate hotel carbon emissions. Hotel Carbon Measurement Initiative (HCMI) is the leading methodology for estimating hotel-related carbon emissions. “HCMI Occupied Room Carbon Footprint” measures the average hotel carbon of occupied rooms while “Effective Occupied Room Carbon Footprint” divides carbon emissions of the hotel buy the number of occupied rooms of the day.",     
                                isSelect: true,     
                                default_val : "HCMI",
                                options : [
                                    {
                                        label: "HCMI Occupied Room Carbon Footprint",
                                        value: "HCMI"
                                    },
                                    {
                                        label: "Effective Occupied Room Carbon Footprint",
                                        value: "Effective"
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        title: "Method 1: Fill in the numbers of rooms of each class",
                        alt_title: "Method 2: Fill in percentage quantities",
                        isSwitchable: true,
                        affectedCounters: {
                            "-b": ["frac_roomsRs"],
                        },
                        fields: [
                            [
                                {
                                    id_name: "h1_num_three_star-a",
                                    label: "Number of 3-star Rooms",
                                    units: "room(s)",
                                    isSelect: false,
                                    default_val : 390,
                                    // options : [
                                    //     {
                                    //         value: "INSERT"
                                    //     },
                                    // ],
                                },
                                {
                                    id_name: "h1_num_four_star-a",
                                    label: "Number of 4-star Rooms",
                                    units: "room(s)",
                                    isSelect: false,
                                    default_val : 180,
                                    // options : [
                                    //     {
                                    //         value: "INSERT"
                                    //     },
                                    // ]
                                },
                                {
                                    id_name: "h1_num_five_star-a",
                                    label: "Number of 5-star Rooms",
                                    units: "room(s)",
                                    isSelect: false,
                                    default_val : 30,
                                    // options : [
                                    //     {
                                    //         value: "INSERT"
                                    //     },
                                    // ]
                                },
                            ],
                            [
                                {
                                    id_name: "h1_frac_single_room-b",
                                    label: "Percentage of People for Single Room",
                                    caption: "Input the proportion of individuals occupying single rooms. The remaining international attendees will be automatically allocated to double rooms.",
                                    description: "Input the proportion of individuals occupying single rooms. The remaining international attendees will be automatically allocated to double rooms.",
                                    units: "%",
                                    isSelect: false,
                                    default_val : 50,
                                    options : [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ],
                                },
                                {
                                    id_name: "h1_frac_three_star-b",
                                    label: "Percentage of 3-star Rooms",
                                    units: "%",
                                    isSelect: false,
                                    default_val : 65,
                                    options : [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ],
                                    counterId: "frac_roomsRs",
                                },
                                {
                                    id_name: "h1_frac_four_star-b",
                                    label: "Percentage of 4-star Rooms",
                                    units: "%",
                                    isSelect: false,
                                    default_val : 30,
                                    options : [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ]
                                },
                                {
                                    id_name: "h1_frac_five_star-b",
                                    label: "Percentage of 5-star Rooms",
                                    units: "%",
                                    isSelect: false,
                                    default_val : 5,
                                    options : [
                                        {
                                            value: "10"
                                        },
                                        {
                                            value: "20"
                                        },
                                        {
                                            value: "30"
                                        },
                                        {
                                            value: "40"
                                        },
                                        {
                                            value: "50"
                                        },
                                        {
                                            value: "60"
                                        },
                                        {
                                            value: "70"
                                        },
                                        {
                                            value: "80"
                                        },
                                        {
                                            value: "90"
                                        },
                                        {
                                            value: "100"
                                        },
                                    ]
                                },
                            ],
                        ]
                    },
                ]
            },
        ]
    },
    "onsite": {
        tabTitle: "On-Site Activities",
        tabDescription: "",
        sections: [
            {
                title: "Venue",
                tag: "o3",
                isOpen: false,
                subsections: [
                    {
                        title: "Rooms",
                        fields: [
                            {
                                id_name: "o3_num_S1",
                                label: "Number of S1 Rooms",
                                caption: "A 70-sqm space with a height of 3 metres.",
                                description: "Input the number of S1 rooms used for the event. An S1 room is 70 sqm with a height of 3 metres. The room has a max capacity of 50~70 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                isSelect: false,     
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_S2",
                                label: "Number of S2 Rooms",
                                caption: "A 125-sqm space with a height of 3 metres.",
                                description: "Input the number of S2 rooms used for the event. An S2 room is 125 sqm with a height of 3 metres. The room has a max capacity of 60~125 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_S3",
                                label: "Number of S3 Rooms",
                                caption: "A 170-sqm space with a height of 3 metres.",
                                description: "Input the number of S3 rooms used for the event. An S3 room is 170 sqm with a height of 3 metres. The room has a max capacity of 80~170 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_M1",
                                label: "Number of M1 Rooms",
                                caption: "A 220-sqm space with a height of 3 metres.",
                                description: "Input the number of M1 rooms used for the event. An M1 room is 220 sqm with a height of 3 metres. The room has a max capacity of 110~220 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_M2",
                                label: "Number of M2 Rooms",
                                caption: "A 500-sqm space with a height of 3 metres.",
                                description: "Input the number of M2 rooms used for the event. An M2 room is 500 sqm with a height of 3 metres. The room has a max capacity of 250~500 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_L1",
                                label: "Number of L1 Rooms",
                                caption: "A 800-sqm space with a height of 3 metres.",
                                description: "Input the number of L1 rooms used for the event. An L1 room is 800 sqm with a height of 3 metres. The room has a max capacity of 400~800 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_L2",
                                label: "Number of L2 Rooms",
                                caption: "A 1000-sqm space with a height of 8 metres.",
                                description: "Input the number of L2 rooms used for the event. An L2 room is 1000 sqm with a height of 8 metres. The room has a max capacity of 500~1000 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_L3",
                                label: "Number of L3 Rooms",
                                caption: "A 1500-sqm space with a height of 8 metres.",
                                description: "Input the number of L3 rooms used for the event. An L3 room is 1500 sqm with a height of 8 metres. The room has a max capacity of 800~1500 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 1,
                            },
                            {
                                id_name: "o3_num_XL1",
                                label: "Number of XL1 Rooms",
                                caption: "A 2000-sqm space with a height of 8 metres.",
                                description: "Input the number of XL1 rooms used for the event. An XL1 room is 2000 sqm with a height of 8 metres. The room has a max capacity of 1000~2000 pax depending on the event setting",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_XL2",
                                label: "Number of XL2 Rooms",
                                caption: "A 4000-sqm space with a height of 10 metres.",
                                description: "Input the number of XL2 rooms used for the event. An XL2 room is 4000 sqm with a height of 10 metres. The room has a max capacity of 2000~4000 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                            {
                                id_name: "o3_num_XL3",
                                label: "Number of XL3 Rooms",
                                caption: "A 12000-sqm space with a height of 10 metres.",
                                description: "Input the number of XL3 rooms used for the event. An XL3 room is 12000 sqm with a height of 10 metres. The room has a max capacity of 6000~12000 pax depending on the event setting.",
                                units: "room(s)",
                                isStepper: true,
                                // maxValue: 10,
                                default_val: 0,
                            },
                        ]
                    },
                    {
                        title: "Desktop Computers",
                        fields: [
                            {
                                id_name: "o3_num_desktops",
                                label: "Total Number of Desktops in Use",
                                description: "Input the total number of desktop computers in use. Each desktop computer is assumed to handle a power of 200 watts.",
                                units: "desktops",
                                default_val: 3,
                                // options: [
                                //     {
                                //         value: "INSERT",
                                //         label: ""
                                //     },
                                // ],
                            },
                        ]
                    },
                    {
                        title: "Audio",
                        fields: [
                            {
                                id_name: "o3_num_speakers",
                                label: "Total Number of Speakers in Use",
                                description: "Input the total number of speakers in use. Each speaker is assumed to handle a power of 1600 watts.",
                                units: "speakers",
                                default_val: 10,
                                // options: [
                                //     {
                                //         value: "INSERT",
                                //         label: ""
                                //     },
                                // ],
                            },
                        ]
                    },
                    {
                        title: "Visual",
                        fields: [
                            {
                                id_name: "o3_num_projectors",
                                label: "Total Number of Projectors in Use",
                                description: "Input the total number of projectors in use. Each projector is assumed to handle a power of 360 watts.",
                                units: "projectors",
                                default_val: 10,
                                // options: [
                                //     {
                                //         value: "INSERT",
                                //         label: ""
                                //     },
                                // ],
                            },
                        ]
                    },
                    {
                        title: "Heating, Ventilation and Air Conditioning (HVAC)",
                        fields: [
                            {
                                id_name: "o3_btu_sqm_h",
                                label: "BTUs per Sqm per Hour",
                                description: "Input the British thermal units (BTUs) per sqm per hour of the HVAC system. BTU is the amount of heat required to raise the temperature of one pound of water by one degree Fahrenheit.",
                                default_val: 200,
                                // options: [
                                //     {
                                //         value: "INSERT",
                                //         label: ""
                                //     },
                                // ],
                            },
                        ]
                    },
                    {
                        title: "Water", // For the following section
                        fields: []
                    },
                    {
                        title: "Method 1: Fill in the water consumption per person per day",
                        alt_title: "Method 2: Direct Estimation",
                        isSwitchable: true,
                        fields: [
                            [
                                {
                                    id_name: "o3_water_consumption_per_attendee-a",
                                    label: "Water Consumption per Attendee per Day",
                                    caption: "Average water usage among physical attendees.",
                                    // description: "TODO",
                                    units: "litres",
                                    default_val: 149,
                                },
                            ],
                            [
                                {
                                    id_name: "o3_total_water_consumption-b",
                                    label: "Total Event Water Consumption",
                                    caption: "Estimated based on the number of attendees and national daily water usage data.",
                                    // description: "TODO",
                                    units: "litres",
                                    default_val: 745000,
                                },
                            ],
                        ]
                    }
                ]
            },
            {
                title: "Communication",
                tag: "o1",
                subsections: [
                    {
                        title: "Paper and Board",
                        isSwitchable: false,
                        fields: [
                            {
                                id_name: "o1_forms_per_attendee",
                                label: "Forms per Attendee per Day",
                                caption: "The number of forms distributed to each physical attendee.",
                                description: "Input the number of forms distributed to each physical attendee. All forms are assumed to be printed on A4-sized uncoated woodfree (UCWF) office paper. These forms are typically used for event programme agenda, checklists, questionnaires and personal information collection, etc. Each form is assumed to be one A4-sized sheet.",
                                units: "pieces",
                                isSelect: false,
                                isStepper: true,
                                default_val : 2,
                            },
                            {
                                id_name: "o1_cp_per_attendee",
                                label: "Commercial Prints per Attendee per Day",
                                caption: "The number of commercial papers distributed to each physical attendee.",
                                description: "Input the number of commercial papers distributed to each physical attendee. All commercial papers are assumed to be printed on A4-sized coated woodfree (CWF) typically used for magazines, books and commercial printing. These commercial papers are usually used for event programme agenda and advertising. Each copy is assumed to be two A4-sized sheets.",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : 10,
                            },
                            {
                                id_name: "o1_brochures_per_attendee",
                                label: "Brochures per Attendee per Day",
                                caption: "The number of brochures distributed to each physical attendee.",
                                description: "Input the number of brochures distributed to each physical attendee. All brochures are assumed to be printed on A4-sized coated groundwood (CGW) typically used for magazines, brochures and business cards. Each copy of the brochure is assumed to be 15 A4-sized sheets (30~60 pages).",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : "2",
                            },
                            {
                                id_name: "o1_tissues_per_attendee",
                                label: "Tissues per Attendee per Day",
                                caption: "The number of tissues needed for each physical attendee.",
                                description: "Input the average number of tissues used by each physical attendee throughout the event period. These tissues may be used at meals or coffee/tea breaks.",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : "20",
                            },
                            {
                                id_name: "o1_tags_per_attendee",
                                label: "Name Tags and Lanyards per Attendee per Day",
                                caption: "The number of name tags distributed to each physical attendee.",
                                description: "Input the number of name tag and lanyard sets distributed to each physical attendee. Both name tags and lanyards are assumed to be made of polyester thread (PET) typically used for printed fabric products.",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : "1",
                            },
                            {
                                id_name: "o1_posters",
                                label: "Exhibition Posters",
                                caption: "The number of posters displayed in the event.",
                                description: "Input the total number of posters used in the event. All posters are assumed to be printed on A0-sized bleached paperboard (BPB) typically used for posters and cardboards.",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : "30",
                            },
                            {
                                id_name: "o1_cartons",
                                label: "Carton Boxes",
                                caption: "The number of carton boxes used for the event.",
                                description: "Input the total number of carton boxes used in the event. All boxes are assumed to be made of unbleached paperboard (UBPB) typically used for cartons and boxes. ",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : "30",
                            },
                            {
                                id_name: "o1_banners",
                                label: "Backdrop Banners",
                                caption: "The number of banners displayed in the event.",                           
                                description: "Input the total number of backdrop banners used in the event. All banners are assumed to be made of vinyl (PVC) typically used for banners and coating of cardboards. Each banner is assumed to be 304.8cm x 234.84cm.",
                                units: "pieces",
                                isSelect: false,     
                                isStepper: true,
                                default_val : 3,
                            },
                        ]
                    },
                    {
                        title: "Electronic Media",
                        isSwitchable: false,
                        fields: [
                            {
                                id_name: "o1_laptop_rate_physical",
                                label: "Laptop Usage Rate",
                                caption: "Average percentage of laptop usage among physical participants.",
                                description: "Input the average percentage of laptop usage among physical attendees. Virtual attendees are assumed to have a laptop usage rate of 100%. Each laptop is assumed to handle a power of 65 watts.",
                                isStepper: true,
                                step: 5,
                                units: "%",
                                default_val: 5,
                                maxValue: 100
                            },
                        ]
                    }
                ]
            },
            {
                title: "Catering",
                tag: "o2",
                subsections: [
                    {
                        fields: [
                            {
                                id_name: "o2_cutlery",
                                label: "Cutlery Choice",
                                caption: "Choose from polystyrene (PS), polypropylene (PP), polylactic acid (PLA), bamboo and stainless steel.",
                                description: "Choose from the dropdown menu the choice of cutlery. Tableware and cutlery are typically made of polystyrene (PS), polypropylene (PP), polylactic acid (PLA), PLA-PP, bamboo and steel. The disposal method also matters to the estimation of carbon footprint from tableware, with incineration and biodegradation as two major disposal methods.",
                                default_val: "Bamboo",
                                isSelect: true,
                                options: [
                                    {
                                        label: "Polystyrene",
                                        value: "PS"
                                    },
                                    {
                                        label: "Polypropylene",
                                        value: "PP"
                                    },
                                    {
                                        value: "PLA-PP (Incineration)",
                                    },
                                    {
                                        value: "PLA-PP (Biodegradation)",
                                    },
                                    {
                                        label: "Polylactic Acid (Incineration)",
                                        value: "PLA (Incineration)",
                                    },
                                    {
                                        label: "Polylactic Acid (Biodegradation)",
                                        value: "PLA (Biodegradation)",
                                    },
                                    {
                                        value: "Bamboo"
                                    },
                                    {
                                        value: "Stainless Steel (Used Once)"
                                    },
                                    {
                                        value: "Stainless Steel (After 29 Uses)"
                                    },
                                ]
                            },
                            {
                                id_name: "o2_meals_per_day",
                                label: "Meals per Day",
                                caption: "Including breakfast/lunch/dinner.",
                                description: "Input the number of meals (breakfast/lunch/dinner) per day. This number should be no greater than the total catering global input. A typical event organiser may arrange 1~3 catered meals per day.",
                                units: "meals",
                                isSelect: false,     
                                isStepper: true,
                                default_val: 2,
                                // counterId: "mealsRs",
                            },
                            {
                                id_name: "o2_teas_per_day",
                                label: "Tea/Coffee Breaks per Day",
                                caption: "Including tea/coffee/snack breaks.",
                                description: "Input the number of tea/coffee breaks per day. This number and the number of meals should add up to the total catering global input. Light refreshments are often provided at tea/coffee breaks by event organisers.",
                                units: "meals",
                                isSelect: false,     
                                isStepper: true,
                                default_val: 2,
                            },
                            {
                                id_name: "o2_num_vegetarians",
                                label: "Number of Vegetarians",
                                caption: "The number of vegetarians among physical attendees.",
                                description: "Input the number of vegetarians among physical attendees. This number should be no greater than the total number of physical attendees global input. By default, 7% of the physical attendees are assumed to opt for the vegetarian option, taking no meat or seafood.",
                                units: "pax",
                                isSelect: false,     
                                default_val: 70,
                                options: [
                                    {
                                        value: "50-100"
                                    },
                                    {
                                        value: "100-300"
                                    },
                                    {
                                        value: "300-500"
                                    },
                                    {
                                        value: "500-800"
                                    },
                                    {
                                        value: "800-1000"
                                    },
                                ],
                                counterId: "vegetarianRs",
                            },
                        ]
                    },
                    {
                        title: "Method 1: Fill in the amount of each ingredient per meal per person in grams ",
                        alt_title: "Method 2: Direct Estimation",
                        isSwitchable: true,
                        fields: [
                            [
                                {
                                    id_name: "o2_leafy_per_meal-a",
                                    label: "Leafy Vegetables",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 100,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_fruits_per_meal-a",
                                    label: "Fruits",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 70,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_wheat_per_meal-a",
                                    label: "Wheat",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 10,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_other_veg_per_meal-a",
                                    label: "Other Vegetables",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 100,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_rice_per_meal-a",
                                    label: "Rice",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 10,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_egg_per_meal-a",
                                    label: "Eggs",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 30,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_chicken_per_meal-a",
                                    label: "Chicken",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 100,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_duck_per_meal-a",
                                    label: "Duck",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 0,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_other_seafood_per_meal-a",
                                    label: "Other Seafood",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 8,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_fish_per_meal-a",
                                    label: "Fish",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 100,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_pork_per_meal-a",
                                    label: "Pork",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 0,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_mutton_per_meal-a",
                                    label: "Mutton",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 0,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o2_beef_per_meal-a",
                                    label: "Beef",
                                    caption: "Weight per meal per person",
                                    units: "grams",
                                    default_val: 0,
                                    options: [
                                        {
                                            value: "0-10"
                                        },
                                        {
                                            value: "10-50"
                                        },
                                        {
                                            value: "50-100"
                                        },
                                        {
                                            value: "100-150"
                                        },
                                        {
                                            value: "150-200"
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    id_name: "o2_food_emission-b",
                                    label: "Total Carbon Footprint of Food",
                                    caption: "Total carbon footprint from food served at event in kg CO\u2082e. Not required if not readily available.",
                                    description: "Input the estimated total carbon footprint of food in kg CO\u2082e. Some meal caterers provide this number for their sample meals. This carbon footprint excludes emissions related to tableware. The default value is provided based on the number of physical attendees, the number of vegetarians and the number of meals provided, considering Singapore adults’ daily consumption rates.",
                                    units: "kg CO\u2082e",
                                    default_val: 14125,
                                },
                            ],
                        ]
                    },
                ]
            },
            {
                title: "Waste",
                tag: "o4",
                subsections: [
                    {
                        title: "Method 1: Fill in the amount by waste type",
                        alt_title: "Method 2: Direct Estimation",
                        isSwitchable: true,
                        fields: [
                            [
                                {
                                    id_name: "o4_metal_waste-a",
                                    label: "Metal Waste",
                                    caption: "Assumed to re-use 100%.",
                                    // description: "TODO",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-1500"
                                        },
                                        {
                                            value: "1500-2000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o4_wood_waste-a",
                                    label: "Wood Waste",
                                    caption: "Assumed to re-use 100%.",
                                    // description: "TODO",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-1500"
                                        },
                                        {
                                            value: "1500-2000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o4_papercardboard_waste-a",
                                    label: "Paper/Cardboard Waste",
                                    caption: "Estimated from paper and board.",
                                    // description: "TODO",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-1500"
                                        },
                                        {
                                            value: "1500-2000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o4_plastic_waste-a",
                                    label: "Plastic Waste",
                                    caption: "Estimated based on plastic usage per person.",
                                    // description: "TODO",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-1500"
                                        },
                                        {
                                            value: "1500-2000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o4_food_waste-a",
                                    label: "Food Waste",
                                    caption: "Estimated from food & beverages catering.",
                                    // description: "TODO",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-2000"
                                        },
                                        {
                                            value: "2000-3000"
                                        },
                                        {
                                            value: "3000-4000"
                                        },
                                        {
                                            value: "4000-5000"
                                        },
                                    ],
                                },
                                {
                                    id_name: "o4_other_waste-a",
                                    label: "Others",
                                    caption: "Assumed to re-use 100%.",
                                    // description: "TODO",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-1500"
                                        },
                                        {
                                            value: "1500-2000"
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    id_name: "o4_total_waste-b",
                                    label: "Total Waste Disposal Amount",
                                    description: "Total amount of waste disposed, including but not limited to metal, wood, paper/cardboard, plastics and food waste.",
                                    units: "kg",
                                    options: [
                                        {
                                            value: "0-500"
                                        },
                                        {
                                            value: "500-1000"
                                        },
                                        {
                                            value: "1000-2000"
                                        },
                                        {
                                            value: "2000-3000"
                                        },
                                        {
                                            value: "3000-4000"
                                        },
                                        {
                                            value: "4000-5000"
                                        },
                                        {
                                            value: "5000-6000"
                                        },
                                        {
                                            value: "6000-7000"
                                        },
                                        {
                                            value: "7000-8000"
                                        },
                                    ],
                                }
                            ],
                        ]
                    },
                ]
            }
        ]
    },
    // "offset": {
    //     // tabTitle: "Carbon Offset",
    //     tabDescription: "",
    //     sections: [
    //         {
    //             title: "Carbon Offset",
    //             tag: "co",
    //             isOpen: true,
    //             subsections: [
    //                 {
    //                     title: "",
    //                     isSwitchable: false,
    //                     fields: [
    //                         {
    //                             id_name: "co_purchased_carbon_credit",
    //                             label: "Purchased Carbon Credit",
    // //                            description: "TBC",    
    //                             units: "kg CO\u2082e", 
    //                             options : [

    //                             ]
    //                         }
    //                     ]
    //                 },
    //             ]
    //         }
    //     ]
    // }
};

export const IdToLabel = {
    g1_event_days: "Number of Event Days",
    g1_hours_per_day: "Event Hours per Day",
    g1_international_attendees: "Total Number of International Attendees",
    g1_physical_attendees: "Total Number of Physical Attendees",
    g1_virtual_attendees: "Total Number of Virtual Attendees",
    g1_attendance_period_is_throughout: "Do All Attendees Attend Throughout Event Period?",
    g1_event_meals_provided: "Are Meals Provided for the Event?",
    g1_setting: "Event Setting",
    h1_hotel_nights: "Number of Hotel Accommodation Nights",
    h1_cf_method: "Hotel Carbon Estimation Method",
    'h1_frac_five_star-b': "Percentage of 5-star Rooms",
    'h1_frac_four_star-b': "Percentage of 4-star Rooms",
    'h1_frac_single_room-b': "Percentage of People for Single Room",
    'h1_frac_three_star-b': "Percentage of 3-star Rooms",
    'h1_num_five_star-a': "Number of 5-star Rooms",
    'h1_num_four_star-a': "Number of 4-star Rooms",
    'h1_num_three_star-a': "Number of 3-star Rooms",
    o1_banners: "Backdrop Banners",
    o1_brochures_per_attendee: "Brochures per Attendee",
    o1_cartons: "Carton Boxes",
    o1_cp_per_attendee: "Commercial Prints per Attendee",
    o1_forms_per_attendee: "Forms per Attendee",
    o1_posters: "Exhibition Posters",
    o1_tags_per_attendee: "Name Tags and Lanyards per Attendee",
    o1_tissues_per_attendee: "Tissues per Attendee",
    'o2_beef_per_meal-a': "Beef",
    'o2_chicken_per_meal-a': "Chicken",
    o2_cutlery: "Cutlery Choice",
    'o2_duck_per_meal-a': "Duck",
    'o2_egg_per_meal-a': "Eggs",
    'o2_fish_per_meal-a': "Fish",
    'o2_food_emission-b': "Total Carbon Footprint of Food",
    'o2_fruits_per_meal-a': "Fruits",
    'o2_leafy_per_meal-a': "Leafy Vegetables",
    o2_meals_per_day: "Meals per Day",
    'o2_mutton_per_meal-a': "Mutton",
    o2_num_vegetarians: "Number of Vegetarians",
    'o2_other_seafood_per_meal-a': "Other Seafood",
    'o2_other_veg_per_meal-a': "Other Vegetables",
    'o2_pork_per_meal-a': "Pork",
    'o2_rice_per_meal-a': "Rice",
    o2_teas_per_day: "Tea/Coffee Breaks per Day",
    'o2_wheat_per_meal-a': "Wheat",
    o3_btu_sqm_h: "BTUs per Sqm per Hour",
    o1_laptop_rate_physical: "Laptop Usage Rate",
    o3_num_L1: "Number of L1 Rooms",
    o3_num_L2: "Number of L2 Rooms",
    o3_num_L3: "Number of L3 Rooms",
    o3_num_M1: "Number of M1 Rooms",
    o3_num_M2: "Number of M2 Rooms",
    o3_num_S1: "Number of S1 Rooms",
    o3_num_S2: "Number of S2 Rooms",
    o3_num_S3: "Number of S3 Rooms",
    o3_num_XL1: "Number of XL1 Rooms",
    o3_num_XL2: "Number of XL2 Rooms",
    o3_num_XL3: "Number of XL3 Rooms",
    o3_num_desktops: "Total Number of Desktops in Use",
    o3_num_projectors: "Total Number of Projectors in Use",
    o3_num_speakers: "Total Number of Speakers in Use",
    't1_air_emission-b': "Total International Travel Emission",
    't1_fraction_economy_class-a': "Percentage of Economy-Class Trips",
    't1_travellers_africa-a': "Travellers from Africa",
    't1_travellers_americas-a': "Travellers from Americas",
    't1_travellers_europe-a': "Travellers from Europe",
    't1_travellers_greater_china-a': "Travellers from East Asia",
    't1_travellers_north_asia-a': "Travellers from North Asia",
    't1_travellers_oceania-a': "Travellers from Oceania",
    't1_travellers_others-a': "Travellers from Other Regions",
    't1_travellers_south_asia-a': "Travellers from South Asia",
    't1_travellers_southeast_asia-a': "Travellers from Southeast Asia",
    't1_travellers_west_asia-a': "Travellers from West Asia",
    t2_local_residence: "Residing Area for International Attendees"
}