import { HashRouter } from 'react-router-dom';
import './App.css';
import Router from './routes';

export default function App() {     
	return (
		<HashRouter>
			<Router />
		</HashRouter>
	);
}