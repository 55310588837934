import {
    styled,
    tableCellClasses
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import '../../../print.css';
import CarbonCreditsPopper from '../popups/Res_CarbonCreditsPopper';
import OffsetInputField from './OffsetInputField';
import ResultGaugeChart from './ResultGaugeChart';

const ModTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.8em',
    },
}));
const StyledTableCell = styled(ModTableCell)(({ bgcolor, theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: 'bgcolor',
        color: bgcolor ? theme.palette.common.white : theme.palette.common.black,
        fontWeight: 700,
    },
}));

export default function ResultOffset({ netValue, offsetValue, calcResults, results, setResults }) {
    const imageTag = 'result_offset';
    const [netEmissions_output, setNetEmissions_output] = useState(null);
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid xs={12} md={6} lg={12}>
                    <CarbonCreditsPopper />
                    <TableContainer>
                        <Table id={'test'} size="small" aria-label="simple table">
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">Carbon Offset (kg CO<sub>2</sub>e)</TableCell>
                                <TableCell align="right">
                                    <OffsetInputField calcResults={calcResults} results={results} setResults={setResults} setNetEmissions_output={setNetEmissions_output} />
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell component="th" scope="row" bgcolor={'#747474'} >Net Event Carbon Emissions (kg CO<sub>2</sub>e)</StyledTableCell>
                                <StyledTableCell align="right" bgcolor={'#747474'}>{netEmissions_output}</StyledTableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid xs={12} md={6} lg={12} p={0} >
                    {/* <Typography variant="h6" sx={{ fontSize: '1.1em' }} gutterBottom>
                        Carbon Offset Credits Percentage
                    </Typography> */}
                    <ResultGaugeChart imageTag={imageTag} pieData={[offsetValue, netValue]} />
                </Grid>
            </Grid>
        </>
    )
}