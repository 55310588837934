import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageFooter from '../../common/PageFooter';
import CustomGridContent from '../../common/components/CustomGridContent.js';
import LoadingPage from '../components/loaders/LoadingPage';
import PageHeader from '../components/nav/PageHeader';
import CounterCheckModal from '../components/popups/CounterCheckModal';
import { Error as E } from '../consts/errorEnum.ts';
import { initCounterData, initFormData, updateCounterData } from '../helpers/FormHelper';
import CalculatorLayout from '../layouts/CalculatorLayout';

export const StageContext = createContext();
export const OverallDataContext = createContext();
export const TabContext = createContext();

const TABS = {
    "global": 0,
    "onsite": 1,
    "transport": 2,
    "hotel": 3,
}

const messages = {
    [E.MissingGlobal] : "Please do not leave any Global Input blank.",
    [E.NonNumeric] : "Please enter positive numeric values only.",
    [E.ExceedValue] : "Please ensure inputs do not exceed the maximum recommended value.",
}

export default function CalculatorPage() {
    const navigate = useNavigate();
    
    // For tracking calculation stage [1: for global (G) inputs, 2: for others (OTH)]
    const [calcStage, setStage] = useState(1);

    // For tracking data that requires additional checks (e.g. count cannot exceed upper bound)
    const [counterData, setCounterData] = useState({});
    const [openCounterCheck, setCounterCheckOpen] = useState(false);

    // States of request and response body data
    const [overallData, setData] = useState({});                    // updated on every input change, and after stage 1 calculation
    const [overallTempData, setTempData] = useState({});                    // used after stage 1 calculation, holds guiding values (for placeholders)
    const [formSubmitData, setSubmitData] = useState({});           // updated upon submission

    // For handling page states [ form page, loading page, results page]
    const [isLoading, setIsLoading] = useState(false);

    // For handling current tab
    const [tabValue, setTabValue] = useState(0);
    const changeTabTo = (tabName) => {
        setTabValue(TABS[tabName]);
    }

    // For tracking errors on form
    const [errors, setErrors] = useState({});

    // For handling error alerts (using <Snackbar> component)
    const [openError, setOpenError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Something went wrong. Please try again.");
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenError(false);
    };

    const requiredInputValidation = () => {
        var form = document.getElementById("calc-form");
        try { form.reportValidity(); } catch (e) {}
        if (form.checkValidity()) return true;
        else return false;
    }

    const checkCounterDataPass = () => {
        var hasAnyMismatch = Object.values(counterData).some(function(element) {
            return element.isMismatched && !element.isDisabled;
        });
        return !hasAnyMismatch;
    }

    
    // Submit 2: To process submission data and handle errors
    const handleSubmissionPrep = () => {
        if (requiredInputValidation()) {
            if (Object.keys(errors).length === 0) {
                var strconfirm = window.confirm("Are you sure you want to submit?");
                if (strconfirm === true) {
                    setIsLoading(true);
                    processInputData();        
                }
            } else {
                setOpenError(true);
                var err_types = new Set(Object.values(errors));
                console.log(err_types)
                var err_msg = "";
                err_types.forEach(type => {
                    err_msg += messages[type] + "\n";
                })
                setErrorMsg(err_msg)
            }
        } else {
            setOpenError(true);
            setErrorMsg("Please do not leave any Global Input blank.")
        }
    };

    // Submit 3: To process and prepare object to send to Lambda function
    const processInputData = () => {
        // console.log(overallData)
        let finalInputs = Object.assign({}, ...Object.values(overallData))
        let requestBody = {
            'rawInputs': finalInputs,
            'calcStage': calcStage
        };
        setSubmitData(requestBody);
        // console.log(requestBody)
    }

    // Submit 4: Only call sendToLambda after all data is processed (i.e. formSubmitData is set)
    useEffect(() => {
        if ( Object.keys(formSubmitData).length !== 0 && isLoading ) {
            const url = process.env.REACT_APP_CC_LAMBDA;
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    httpMethod: 'POST',
                    path: '/calculate',
                    ...formSubmitData
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(
                function (response) {
                    return response.json();
                }
            ).then(
                function(data) {
                    setIsLoading(false);
                    let body = JSON.parse(data.body);
                    // console.log(body);
                    if (data.status === "success") {
                        if (calcStage === 1) {
                            setStage(2);
                            setData(body);
                            setTempData(body);
                        } else {
                            navigate(
                                process.env.REACT_APP_CC_ROOT + '/results',
                                { state : {
                                    calcResults : body
                                }}
                            )
                        }
                    } else if (data.status === "error") {
                        setOpenError(true);
                        if (process.env.NODE_ENV === 'development') {
                            setErrorMsg(body.message);
                        }
                        else setErrorMsg("Something went wrong. Please try again.");
                    }
                }
            ).catch(
                function(error) {
                    if (process.env.NODE_ENV === 'development') {
                        if (typeof error.json === "function") {
                            error.json().then(jsonError => {
                                console.log("Json error from API");
                                console.log(jsonError);
                            }).catch(genericError => {
                                console.log("Generic error from API");
                                console.log(error.statusText);
                            });
                        } else {
                            console.log("Fetch error");
                            console.log(error);
                        }
                    }
                    setIsLoading(false);
                    setErrorMsg("Something went wrong. Please try again.");
                }
            );
        }
    }, [formSubmitData, calcStage, isLoading]);

    // Submit 1: Handler function (onClick action called by submit button)
    const handleSubmitButtonPress = (event) => {
        event.preventDefault();
        if (calcStage === 1 || checkCounterDataPass()) handleSubmissionPrep();
        else setCounterCheckOpen(true);
    };

    useEffect(() => {
        try { updateCounterData( overallData, counterData, setCounterData ); }
        catch (e) { console.log(e) }
    },[overallData]);

    // Initialise form and counter data on page load
    useEffect(() => {
        if (calcStage === 1) initFormData(setData);
        try { initCounterData(overallData, setCounterData); }
        catch {}
    }, [calcStage]);

    useEffect(() => {
        window.addEventListener("beforeunload", (ev) => 
        {  
            ev.preventDefault();
            return (ev.returnValue = 'Are you sure you want to close?');
        });
    });

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <StageContext.Provider value={[calcStage, setStage]} >
                <PageHeader />
                <Box className="light-background" sx={{ height: '100%', flexGrow: '1' }}>
                    <TabContext.Provider value={{ handler: [tabValue, setTabValue], changeTabTo: changeTabTo }}>
                        <OverallDataContext.Provider value={{allData:[overallData, setData], counterData: [counterData, setCounterData], errorData: [errors, setErrors], tempData: overallTempData}} >
                            {
                                <CustomGridContent>
                                    {
                                        isLoading ?
                                        <LoadingPage />
                                    :
                                        <CalculatorLayout handleSubmit={handleSubmitButtonPress} />
                                    }
                                </CustomGridContent>
                            }
                            {
                                (calcStage === 2) &&
                                <CounterCheckModal open={openCounterCheck} setOpen={setCounterCheckOpen} handleSubmit={handleSubmissionPrep} />
                            }
                        </OverallDataContext.Provider>
                    </TabContext.Provider>

                    <Snackbar open={openError} autoHideDuration={8000} onClose={handleClose}>
                        { errorMsg &&
                            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }} elevation={6} variant="filled">
                                {errorMsg}
                            </Alert>
                        }
                    </Snackbar>
                </Box>
                <PageFooter />
            </StageContext.Provider>
        </Box>
    );
}
