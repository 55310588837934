import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useContext, useEffect } from 'react';
import TabPanel from '../../common/components/nav/TabPanel';
import CalcTabs from '../components/nav/CalcTabs';
import TabContent from '../components/nav/TabContent';
import { StageContext, TabContext } from '../pages/CalculatorPage';

export default function CalculatorLayout({ handleSubmit }) {
    const [calcStage, setStage] = useContext(StageContext);
    const [tabValue, setTabValue] = useContext(TabContext).handler;
    
    // Handle tab switching
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        if (calcStage === 2) setTabValue(1);
        else setTabValue(0);
    }, [calcStage])

    return (
        <>
            <CalcTabs position="top" stage={calcStage} tabValue={tabValue} handleTabChange={handleTabChange} />
            <form id="calc-form" method="POST">
            {
                calcStage === 1 ?
                    <>
                        <TabPanel value={tabValue} index={0}>
                            <TabContent tabName="global" />
                        </TabPanel>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '1.5em 0' }}>
                            <Button type='submit' className='pill_button submit_button' onClick={handleSubmit}>Next <KeyboardDoubleArrowRight /></Button>
                        </Box>
                    </>
                :
                    <>
                        <TabPanel value={tabValue} index={0}>
                            <TabContent tabName="global" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <TabContent tabName="onsite" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <TabContent tabName="transport" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <TabContent tabName="hotel" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <TabContent tabName="offset" />
                        </TabPanel>
                        <Box className='calc-buttongroup' margin='1.5em 0' >
                            <Button className='pill_button secondary_button' onClick={() => {setTabValue(0); setStage(1);}}><KeyboardDoubleArrowLeft />Back | Reset Global Inputs</Button>
                            {/* <Button className='pill_button secondary_button' onClick={() => {window.location.reload();}}><KeyboardDoubleArrowLeft />Back | Reset Global Inputs</Button> */}
                            <Button type='submit' className='pill_button submit_button' onClick={handleSubmit}>Calculate</Button>
                        </Box>
                    </>
            }
            </form>
        </>
    )
}