import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useState } from 'react';
import { Error as E } from '../../consts/errorEnum.ts';
import { coalesce } from '../../helpers/FormHelper.js';
import { OverallDataContext, StageContext } from '../../pages/CalculatorPage.js';
import FieldCounter from '../FieldCounter.js';
import FormInputPrompt from '../popups/FormInputPrompt.js';
import InputNumField from './InputNumField.js';
import InputStepperField from './InputStepperField.js';
import SelectField from './SelectField.js';

export default function FormInputField({ field, tabName, onChangeHandler }) {
    const calcStage = useContext(StageContext)[0];

    // For input errors
    const [errors, setErrors] = useContext(OverallDataContext).errorData;
    const [errorMsg, setErrorMsg] = useState("");

    const id_name = coalesce(field.id_name, null);
    const label = coalesce(field.label, '');
    const caption = coalesce(field.caption, '');
    const options = coalesce(field.options, []);
    const description = coalesce(field.description, '');
    const units = coalesce(field.units, '');
    const step = coalesce(field.step, 1);
    const maxValue = coalesce(field.maxValue, null);
    const isSelect = coalesce(field.isSelect, false);
    const isStepper = coalesce(field.isStepper, false);
    const isRequired = coalesce(field.isRequired, false);
    const counterId = coalesce(field.counterId, "");
    const isReadOnly = coalesce(field.isReadOnly, (
        calcStage===2 && tabName==='global' ? true : false
    ));

    const [isInFocus, setFocus] = useState(false);

    const regexPattern = /^((>)?[0-9]\d*|[0-9]\d*((\.|-)\d+)?)$/g
    const checkInputValue = (field_id, field_value) => {
        const fieldData = {
            name: field_id,
            value: field_value,
        }
        if (regexPattern.test(field_value) || field_value==="" ) {
            setErrorMsg("");
            var tempErrors = errors;
            if (field_id in tempErrors) delete tempErrors[field_id];
            setErrors(tempErrors);

            onChangeHandler(fieldData);
        } else {
            setErrorMsg("Please enter positive numeric values only.");
            setErrors({
                ...errors,
                [field_id]: E.NonNumeric
            })
        }

        if (maxValue !== null && parseInt(field_value) > maxValue) {
            setErrorMsg(`This value is too large. The max value for this field is ${maxValue.toString()}.`)
            setErrors({
                ...errors,
                [field_id]: E.ExceedValue
            })
        }
    }
    
    return (
        <>
            {counterId && 
                <FieldCounter counterId={counterId} />
            }
            <Box className="form-field" borderLeft={isInFocus ? '4px solid var(--pri-yellow)' : '4px solid white'}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <label htmlFor={id_name} className={ isRequired ? "required" : "" }>
                                { label }
                            </label>
                            { caption && 
                                <div>
                                    <Typography color="text.secondary" variant='caption'>{ caption }</Typography>
                                </div>
                            }
                        </Box>
                        <FormInputPrompt id_name={id_name} content={description} />
                    </Grid>
                    <Grid xs={units ? 9 : 12} md={units ? 5 : 6} lg={units ? 5 : 6} className='centered-container'>
                        { 
                            isSelect ?
                                <SelectField
                                    key={"select_"+id_name}
                                    id_name={ id_name }
                                    tabName={tabName}
                                    onChangeHandler={onChangeHandler}
                                    options={options}
                                    isReadOnly={isReadOnly}
                                    setFocus={setFocus}
                                />
                            : isStepper ?
                                <InputStepperField
                                    key={"stepper_"+id_name}
                                    step={step} maxValue={maxValue}
                                    id_name={ id_name }
                                    tabName={tabName}
                                    isRequired={isRequired}
                                    isReadOnly={isReadOnly}
                                    checkInputValue={checkInputValue} 
                                    errorMsg={errorMsg} 
                                    setFocus={setFocus}
                                />
                            :
                                <InputNumField 
                                    key={"input_"+id_name}
                                    id_name={ id_name }
                                    tabName={tabName}
                                    isRequired={isRequired}
                                    isReadOnly={isReadOnly}
                                    checkInputValue={checkInputValue} 
                                    errorMsg={errorMsg} 
                                    options={options}
                                    setFocus={setFocus}
                                />
                        }
                    </Grid>
                    {
                        units &&
                        <Grid xs={units ? 3 : 0} md={units ? 1 : 0} lg={units ? 1 : 0} className='centered-container' sx={{overflow: 'clip'}}>
                            <Typography color="text.secondary">{ units }</Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}