import {
    counterRelationships as cr,
    FormStructure as fs
} from "../consts/formStructure";

export function coalesce(given, backup) {
    return given !== undefined ? given : backup
}

export function sortObj(obj, isReverse=false) {
    if (isReverse) {
        return Object.keys(obj).sort().reverse().reduce(function (result, key) {
            result[key] = obj[key];
            return result;
        }, {});
    } else {
        return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
        }, {});
    }
}

// initFormData: To set initial (tab) data and section statuses using the default values given in formStructure
// params:
// - setData (hook function)
export function initFormData( setData ) {
    let defaultData = {};

    Object.keys(fs).forEach((tab) => {
        defaultData[tab] = {};
        let tabData = {};

        fs[tab].sections.forEach((section) => {
            section.subsections.forEach((subsection) => {
                let sub_fields = subsection.fields;
                if (subsection.isSwitchable) {
                    sub_fields = subsection.fields[0].concat(subsection.fields[1]);
                }
                sub_fields.forEach((field) => {
                    tabData[field.id_name] = null;
                });
            });
        });
        defaultData[tab] = tabData;
    });
    setData(defaultData);
}

// initCounterData
// - overallData (hook data object)
// - setCounterData (hook function)
export function initCounterData( overallData, setCounterData ) {
    let defaultData = {};

    Object.entries(cr).forEach(([key, data]) => {
        defaultData[key] = {
            isDisabled: false, // true if section is 'switched off' for SwitchableSubsections: TODO check if doable
            isAlwaysShown: data.isAlwaysShown, // determines whether counter is shown, but modal sect is always shown
            tab_a: data.group1.tab, // fs given value
            value_a: null, // sum to update
            label_a: data.group1.label, // [] need to join
            tab_b: data.group2?.tab, // fs given value
            value_b: null, // sum to match
            label_b: data.group2?.label, // [] need to join
            tabName: data.tabName, // to use to navigate to specific tab
            isMismatched: false,
        };
    });
    updateCounterData( overallData, defaultData, setCounterData )
}

// updateOverallData: Event handler used to update overall data every time tab data is changed
// params:
// - fieldData (data object derived from event (target and value))
// - setData (hook function)
// - overallData (hook data object)
// - setTabData (hook function)
// - tabData (hook data object)
// - tabName (string to identify _Tab component)
export function updateOverallData( fieldData, setData, overallData, setTabData, tabData, tabName ) {
    const targetName = fieldData.name;
    const targetVal = fieldData.value !== "" ? fieldData.value : null;

    let newTabData = {
        ...tabData,
        [targetName]: targetVal
    };
    setTabData(newTabData)

    let oldTabData = overallData[tabName];
    let newOverallData = {
        ...overallData,
        [tabName]: {
            ...oldTabData,
            [targetName]: targetVal
        }
    };
    setData(newOverallData);
}

export function updateSwitchableData(tabName, inactiveSection, section_tag, overallData, setData, counterData, setCounterData, affectedCounters) {
    const searchKey = "-" + inactiveSection;

    let newTabData = overallData[tabName];
    Object.keys(newTabData).forEach( field => {
        // if (field.includes(section_tag) && field.includes(searchKey)) {
        //     newTabData[field] = null;
        // }
        if (affectedCounters) {
            var newCounterData = counterData;
            Object.entries(affectedCounters).forEach( ([subsectionKey, counterTags]) => {
                if (searchKey === subsectionKey) {
                    counterTags.forEach((tag) => { newCounterData[tag].isDisabled = true; })
                }
            })
        }
    })

    let newOverallData = {
        ...overallData,
        [tabName]: {
            ...newTabData
        }
    };
    setData(newOverallData);
}

function parseInputValue(input) {
    if (input !== undefined) {
        if (input === null) return 0;
        else if (typeof input === "string") {
            // Handle range options with support for negative numbers, e.g., "-10--5" gets evaluated as -7.5
            if (input.indexOf("-") !== -1 && input.indexOf("e") === -1) {
                // This regular expression will split by a hyphen not preceded by a number or followed by a number (avoiding splitting negative signs)
                var values = input.split(/(?<!\d)-|-(?!\d)/);
                return values.reduce((a, b) => parseFloat(a) + parseFloat(b)) / values.length;
            } else {
                try { return parseFloat(input); }
                catch (e) {}
                try { return parseInt(input); }
                catch (e) {}
            }
        }
        return input;
    } else return 0;
}
// updateCounterData
// - overallData (hook data object)
// - counterData (hook data object)
// - setCounterData (hook function)
export function updateCounterData( overallData, counterData, setCounterData ) {
    Object.entries(counterData).forEach(([key, data]) => {
        var newData = data;
        const tabName = cr[key].tabName;
        const inputs_a_list = cr[key].group1.inputs;
        var sum_value_a = 0;
        inputs_a_list.forEach((input) => {
            var newinput = parseInputValue(overallData[tabName][input]);
            sum_value_a += newinput;
        })
        newData['value_a'] = sum_value_a;
        // TODO: this needs to be equal sum_value_b if 

        const inputs_b_list = cr[key].group2?.inputs;
        var sum_value_b = 100;
        if ( inputs_b_list ?? false) {
            sum_value_b = 0;
            inputs_b_list.forEach((input) => {
                sum_value_b += parseInputValue(overallData['global'][input]);
            })
        }
        newData['value_b'] = Math.ceil(sum_value_b);

        newData['isMismatched'] = ( (newData['value_a'] !== newData['value_b'] && data.isAlwaysShown) || (newData['value_a'] > newData['value_b'] && !data.isAlwaysShown) );

        setCounterData({
            ...counterData,
            [key]: newData
        });
    });
}