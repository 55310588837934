import { CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CalculatorPage from "./CalculatorPage";
import RequestAccessPage from './RequestAccess';

const TURN_ON_REGISTRATION = true;
export default function Access() {
    const [ isLoading, setLoading ] = useState(TURN_ON_REGISTRATION);
    const [ isAccessible, setAccessibility ] = useState(!TURN_ON_REGISTRATION);

    const url = process.env.REACT_APP_CCP_USER_LAMBDA;
    const checkUserAPI = (email = null) => {
        var fetch_url = url + (email ? '?' + new URLSearchParams({ email: email }) : '');
        return fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            // console.log(response)
            return response.json();
        }).then(data => {
            // console.log(data)
            setAccessibility(data?.success || false)
            return data;
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        }).finally(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        if (TURN_ON_REGISTRATION) checkUserAPI();
    }, [])

    return (
        <>
        {
            isLoading ?
                <Stack className="centered-container" sx={{ minHeight: '60vh' }}>
                    <CircularProgress />
                </Stack>
            : isAccessible ?
                <CalculatorPage />
            :
                <RequestAccessPage checkUserAPI={checkUserAPI} isLoading={isLoading} setAccessibility={setAccessibility} />   
        }
        </>
    )
}