import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import logoImg from '../../images/NUS_SGFIN_logo.png';
import DesktopNavItem from './DesktopNavItem';
import NavSidebar from './NavSidebar';

export default function ResponsiveNavBar({ navs=[] }) {
	return (
		<AppBar className='hide-on-print' position="sticky" sx={{ backgroundColor: 'white', boxShadow: 'none' }} >
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					{/* Mobile Nav Menu + to other sub apps */}
					<NavSidebar navs={navs} />

					{/* Desktop Nav Logo */}
					<Box sx={{ width: '20%', minWidth: '20%', my: 2, ml: 4, mr: 8, display: { xs: 'none', md: 'flex' } }} >
						<a href="/">
							<img src={logoImg} style={{ width: '100%', marginBottom: 4 }} alt="NUS SGFIN logo" />
						</a>     
					</Box>

					{/* Mobile Nav Logo */}
					<Box sx={{ my: 2, display: { xs: 'flex', md: 'none' }, justifyContent: 'center', flexGrow: 1 }} >
						<a href="/" style={{ display: 'flex' }}>
							<img src={logoImg} style={{ maxWidth: '60%', margin: 'auto' }} alt="NUS SGFIN logo" />
						</a>
					</Box>
					
					{/* Desktop Nav Menu */}
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
						{navs.map((navitem, index) => (
							<DesktopNavItem item={navitem} key={index} />
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
