import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import PageFooter from '../../common/PageFooter';
import image from '../images/404image.png';

const Subheader = styled(Typography) ({
    fontSize: '1.2em',
    fontWeight: '700',
    marginBottom: '1em'
})

export default function NotFoundPage() {
    const navigate = useNavigate();
    return (
        <>
        <Box>
            <Box sx={{ height: '40vh', width: '100%', background: `lightgrey url(${image}) no-repeat center`, backgroundSize: 'cover' }} >
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '4em' }}>
                <Subheader variant='h4'>
                    404
                </Subheader>
                <Typography variant='h3'>
                    Page Not Found
                </Typography>
                <Typography variant='body1' color='text.secondary' marginY="2em">
                    The page you are looking for might have been removed, had its name changed or is temporarily unavilable.
                </Typography>
                <Button className='pill_button submit_button' onClick={() => navigate(process.env.REACT_APP_CC_ROOT_HOME +'/')}>Go to MICE Carbon Calculator homepage</Button>
            </Box>
        </Box>
        <PageFooter />
    </>
    )
}