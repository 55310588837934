import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { showErrors } from "../../common/consts/helpers";
import Spinner from "../../SMEsustainability/components/Spinner";
import DataHubPage from './DataHub';
import RequestAccessPage from './RequestAccess';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function Access() {
    const navigate = useNavigate();
    const query = useQuery();
    const [ token, setToken ] = useState(query.get('t'));

    const [ isLoading, setLoading ] = useState(true);
    const [ isAccessible, setAccessibility ] = useState(false);
    const [dataGridRows, setRows] = useState([]);
    const [dataGridFilters, setFilters] = useState({});

    const url = process.env.REACT_APP_EDH_LAMBDA;
    const checkEmailAPI = (email = '') => {
        return fetch(url + '?' + new URLSearchParams({ email: email }), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            // console.log(response)
            return response.json();
        }).then(data => {
            // console.log(data)
            if (data?.success) setToken(data?.token);
            return data;
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            return {};
        });
    }

    const setExpiryCookie = (token) => {
        var now = new Date();
        var time = now.getTime();
        var ttl = 7200000; // 2 hours
        var expireTime = time + ttl;
        now.setTime(expireTime);
        document.cookie = `sgfin-edhaccess=${token}; Secure; Expires=`+now.toUTCString();
    }
    const getCookie = (name) => {
        var match_cookie = document.cookie.match(new RegExp('(^| )'+name+'=([^;]+)'));
        var cookie_val = match_cookie ? match_cookie[2] : '';
        return cookie_val;
    }
    function eraseCookie(name) {   
        document.cookie = name+'=; Max-Age=-99999999;';  
    }

    const handleFetch = (token) => {
        setLoading(true);
        fetch(url + 'datahub?' + new URLSearchParams({ t: token }), 
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(
            function (response) {
                return response.json();
            }
        ).then(
            function(data) {
                // console.log(data)
                setAccessibility(data?.success)
                if (data?.success) {
                    setExpiryCookie(token);
                    setRows(data?.results);
                    setFilters({ ...data?.filterOptions, filterHierarchy: data?.filterHierarchy });
                    navigate(process.env.REACT_APP_EDH_ROOT, {})
                } else {
                    eraseCookie('sgfin-edhaccess');
                    navigate(
                        process.env.REACT_APP_EDH_ROOT,
                        { state: {
                            errorMsg: data?.message
                        }}
                    )
                }
                setLoading(false);
            }
        ).catch(
            function(error) {
                showErrors(error);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        navigate(process.env.REACT_APP_EDH_ROOT, {});
        var cookie_token = getCookie('sgfin-edhaccess');
        if (cookie_token !== '') setToken(cookie_token);
        setLoading(false);
    }, [])

    useEffect(() => {
        if (token) handleFetch(token);
        else setAccessibility(false);
    }, [token])

    if (isLoading) return (
        <Stack className="centered-container" sx={{ minHeight: '60vh' }}>
            <Spinner width={'100px'} />
        </Stack>
    );
    else {
        if (isAccessible) return <DataHubPage dataGridRows={dataGridRows} dataGridFilters={dataGridFilters} isLoading={isLoading} />
        else return <RequestAccessPage checkEmailAPI={checkEmailAPI} />
    }
}