export const sgfintech_apps = [
    {
        title: "MICE Carbon Calculator",
        desc: "A toolkit built to help event organisers, planners and regulators calculate and track carbon footprint for Meetings, Incentives, Conventions and Exhibitions (MICE) in Singapore.",
        icon: "material-symbols:calculate",
        tag: "BETA",
        path: process.env.REACT_APP_CC_ROOT_HOME,
    },
    {
        // inDevelopment: true, // TODO: add this to WIP apps only
        title: "Corporate Emission Pricing",
        desc: "This machine learning-powered calculator quantifies the impact of corporate GHG emissions on market equity value after accounting for firms' financial features, aiding corporate leaders and stakeholders in their decision-making process.",
        icon: "mdi:graph-areaspline",
        // carbon:carbon-ui-builder
        tag: "BETA",
        path: process.env.REACT_APP_CCP_ROOT,
    },
    {
        title: "Environmental Data Hub",
        desc: "Dive into Southeast Asia's corporate sustainability landscape with our data hub. Access 3,500+ environmental data points related to GHG emissions, energy, water, and waste from 2019 to 2022.",
        icon: "ion:earth",
        tag: "BETA",
        path: process.env.REACT_APP_EDH_ROOT,
    },
    // {
    //     inDevelopment: true,
    //     isLocked: true,
    //     title: "SME Sustainability Survey",
    //     desc: "A survey designed to gain insights into your company's sustainable practices encompassing environmental, social, governance, and financial aspects.", 
    //     icon: "ri:survey-fill",
    //     tag: "Dev",
    //     path: process.env.REACT_APP_SME_ROOT,
    // },
    // {
    //     inDevelopment: true,
    //     isLocked: true,
    //     title: "Green Building IROI Calculator",
    //     desc: "", // TODO
    //     icon: "clarity:building-solid",
    //     tag: "Dev",
    //     path: process.env.REACT_APP_GBIC_ROOT,
    // },
    // {
    //     inDevelopment: true,
    //     title: "Plots & Reports",
    //     isLocked: true,
    //     desc: "", // TODO
    //     icon: "bxs:report",
    //     tag: "POC",
    //     path: '/report0207',
    // },
    // {
    //     inDevelopment: true,
    //     title: "SGFINsights",
    //     desc: "", // TODO
    //     icon: "bxs:report",
    //     path: '/SGFINsights/Commitments',
    // },
    {
        title: "More Coming Soon...", // TEMP
        desc: "Watch this space",
        icon: "mdi:new-releases",
        path: "#",
    },
];