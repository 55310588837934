import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '70vw',
  maxWidth: '80vw',
  maxHeight: '75vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4,
  overflow: 'auto'
};

export default function ModalBase({ children, title, opener, open, setOpen }) {
    const handleClose = () => setOpen(false);
    return (
        <div>
            {opener}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-title" variant="h5">
                            {title}
                        </Typography>
                        <CloseIcon sx={{color: 'text.secondary', '&:hover': { color: 'text.primary'}}} onClick={handleClose} />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        { children }
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}