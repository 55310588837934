import Calculate from '@mui/icons-material/Calculate';
import Home from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomGridContent from '../../../common/components/CustomGridContent';
import '../../../print.css';
import logoImg from '../../images/NUS_SGFIN_logo.png';
import CalcModal from '../popups/CalcModal';
import IconButton from './IconButton';

export default function PageHeader({ modalDefaultOpen=true }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <Box className="remove-padding-print" sx={{ padding: 3, borderBottom: 1, borderColor: '#C6C6C6' }}>
            <CustomGridContent>
                    <Box >
                        <Grid container>
                            <Grid xs={10} sm={10}>
                                <a href={"#" + process.env.REACT_APP_CC_ROOT} style={{ textDecoration: 'none' }}>
                                    <img src={logoImg} style={{ maxHeight: '40px', maxWidth: '70%', marginBottom: 4 }} alt="NUS SGFIN logo" />
                                        <Typography variant="h5" sx={{ color: "#1976d2" }}>
                                        {/* <Typography variant="h4" sx={{ color: "var(--pri-blue)" }}> */}
                                            MICE Carbon Calculator
                                        </Typography> 
                                        <Box
                                            className="hide-on-print" 
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                px: 2,
                                                py: 0.5,
                                                borderRadius: '2em',
                                                backgroundColor: 'var(--btn-green)'
                                            }}
                                        >
                                            <Typography variant='subtitle2' sx={{ fontSize: '0.6em', color: "white" }}>
                                            BETA
                                            </Typography> 
                                        </Box>
                                </a>             
                            </Grid>
                            <Grid xs={2} sm={2} className="hide-on-print">
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton icon={<Home />} label="Home" onClickAction={() => navigate(process.env.REACT_APP_CC_ROOT +'/')}/>
                                </Box>
                                { (pathname === process.env.REACT_APP_CC_ROOT +'/calculator') ?
                                    <CalcModal defaultOpen={modalDefaultOpen} />
                                    :
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton icon={<Calculate />} label="Calculator" onClickAction={() => navigate(process.env.REACT_APP_CC_ROOT +'/calculator')}/>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Box>
            </CustomGridContent>
        </Box>
    )
}