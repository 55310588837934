import Search from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default function SearchBar({ searchInput, handleFetch, setSearchInput, setSearchState }) {
    const handleChange = (e) => {
        setSearchInput(e.target.value.trim())
        if (e.target.value.trim()) handleFetch();
    }

    return (
        <Box sx={{ backgroundColor: 'white', borderRadius: '28px', p: 1, width: '100%' }} >
        <Autocomplete
            freeSolo
            options={[]}
            renderInput={(params) => 
                <TextField 
                    {...params}
                    name={ 'ccp_searchbar' } id={ 'ccp_searchbar' }
                    value={searchInput}
                    onChange={handleChange}
                    type="text"
                    placeholder="Search for companies, symbols or exchanges"
                    aria-label="Search for companies, symbols or exchanges"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                        ),
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                          </>
                        )
                    }}
                    onFocus={() => setSearchState(true)}
                />
            }
        />
    </Box>
    )
}