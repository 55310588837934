import Box from "@mui/material/Box";

const COLOURS = {
    bg: {
        more: "#ffa3a3",
        less: "#ffe380",
        equal: "#BFFFAF"
    },
    text: {
        more: "#600000",
        less: "#643A00",
        equal: "#00470F"
    }
}

export default function CounterPill({ value_a, value_b }) {
    return (
        <Box
            sx={{
                paddingY: 1,
                paddingX: 3,
                display: 'inline-block',
                borderRadius: '24px',
                backgroundColor: (value_a > value_b) ? COLOURS.bg.more : (value_a < value_b) ? COLOURS.bg.less : (value_a !== value_b) ? COLOURS.bg.more : COLOURS.bg.equal,
            }}
        >
                <span style={{ color: (value_a > value_b) ? COLOURS.text.more : (value_a < value_b) ? COLOURS.text.less : (value_a !== value_b) ? COLOURS.text.more : COLOURS.text.equal }}>{value_a} / {value_b}</span>
        </Box>
    )
}