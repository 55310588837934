import { getGridSingleSelectOperators } from "@mui/x-data-grid";

const DECIMAL_RULE = {maximumFractionDigits: 2, minimumFractionDigits: 2};

const ssFilterOperators = getGridSingleSelectOperators()
.filter((operator) => operator.value === "is")
.map((operator) => {
    const newOperator = { ...operator }
    const newGetApplyFilterFn = (filterItem, column) => {
    return (params) => {
        let isOk = true
        filterItem?.value?.forEach((fv) => {
        isOk = isOk && params.value.includes(fv)
        })
        return isOk
    }
    }
    newOperator.getApplyFilterFn = newGetApplyFilterFn
    return newOperator
})

const formatCols = (cols) => {
    cols.forEach((col, k) => {
        if (col.field.substring(0,4)==="data") {
            cols[k] = {
                ...cols[k],
                filterOperators: ssFilterOperators,
                renderCell: (params) => (
                    <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {
                        ( params.value === null || params.value === undefined ) ? ''
                        :
                        `${params.value?.toLocaleString('en-US', DECIMAL_RULE)}`
                    }
                    </div>
                ),
            }
        }
    });
    return cols;
};

export const dataGridColumns = formatCols([
    // { 
    //     field: 'id', 
    //     headerName: '',
    //     cellClassName: 'col-centered',
    //     minWidth: 56,
    //     maxWidth: 56,
    //     disableColumnMenu: true
    // },
    { 
        field: 'company_name', 
        headerName: 'Company Name',
        headerClassName: 'col-grey',
        minWidth: 250,
        maxWidth: 500,
    },
    { 
        field: 'country',
        headerName: 'Country',
        headerClassName: 'col-grey',
        minWidth: 120,
        maxWidth: 500,
    },
    // { 
    //     field: 'industry_name',
    //     headerName: 'Industry',
    //     headerClassName: 'col-grey',
    //     minWidth: 150,
        // maxWidth: 500,
    // },
    { 
        field: 'sector_name',
        headerName: 'Sector',
        headerClassName: 'col-grey',
        minWidth: 120,
        maxWidth: 500,
    },
    { 
        field: 'ticker',
        headerName: 'Ticker',
        headerClassName: 'col-grey',
        minWidth: 100,
        maxWidth: 500,
    },
    { 
        field: 'fiscal_year',
        headerName: 'Fiscal Year',
        headerClassName: 'col-grey',
        minWidth: 60,
        maxWidth: 200,
    },
    {
        field: 'data_ghg_scope1',
        headerName: 'GHG Scope 1 (tCO₂e)' ,
        headerClassName: 'col-green',
        cellClassName: 'col-centered',
        minWidth: 120,
        maxWidth: 500,
    },
    {
        field: 'data_ghg_scope2',
        headerName: 'GHG Scope 2 (tCO₂e)' ,
        headerClassName: 'col-green',
        cellClassName: 'col-centered',
        minWidth: 120,
        maxWidth: 500,
    },
    {
        field: 'data_total_energy_use',
        headerName: 'Energy Use, Total (GJ)' ,
        headerClassName: 'col-red',
        cellClassName: 'col-centered',
        minWidth: 120,
        maxWidth: 500,
    },
    {
        field: 'data_total_electricity_use',
        headerName: 'Electricity Use, Total (GJ)' ,
        headerClassName: 'col-red',
        cellClassName: 'col-centered',
        minWidth: 120,
        maxWidth: 500,
    },
    {
        field: 'data_total_waste',
        headerName: 'Total Waste (Metric Tonnes)' ,
        headerClassName: 'col-cream',
        cellClassName: 'col-centered',
        minWidth: 120,
        maxWidth: 500,
    },
    {
        field: 'data_total_water_use',
        headerName: 'Water Use, Total (m³)' ,
        headerClassName: 'col-blue',
        cellClassName: 'col-centered',
        minWidth: 120,
        maxWidth: 500,
    },
    {
        field: 'data_total_water_withdrawn',
        headerName: 'Water Withdrawal, Total (m³)' ,
        headerClassName: 'col-blue',
        cellClassName: 'col-centered',
        minWidth: 140,
        maxWidth: 500,
    },
]);
