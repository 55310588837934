import Grid from "@mui/material/Unstable_Grid2";
import InfoCard from './components/cards/InfoCard';
import { sgfintech_apps } from './consts/apps';

export default function AppCardLinks({ withDesc=true }) {
    const visibleApps = sgfintech_apps.filter(app => (!app.inDevelopment) || (app.inDevelopment && process.env.REACT_APP_BRANCH !== 'main'));
    const visibleAppsCount = visibleApps.length;
    return (
        <Grid container spacing={3}>
            {
                visibleApps.map((app, app_key) => {
                    return (
                        <Grid key={app_key} 
                            xs={12} 
                            sm={!withDesc || ((app_key+1)===visibleAppsCount && (app_key+1)%2===1) || visibleAppsCount===2  ? 12 : 6}
                        >
                            <InfoCard 
                                title={app.title}
                                description={withDesc && app.desc}
                                icon={app.icon}
                                tag={app.tag}
                                path={app.path}
                                tagColor={app.inDevelopment && '#f2cc4f'}
                                isLocked={app.isLocked}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}