import { Icon } from '@iconify/react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppCardLinks from '../../../common/AppCardLinks';
import MobileNav from './MobileNav';

export default function NavSidebar({ navs }) {
    const navigate = useNavigate();
	const [drawerOpen, setOpen] = useState(false);

	const toggleDrawer = (isOpen) => (event) => {
		setOpen(isOpen);
	}
    return (
        <>
            <IconButton
                aria-label="close button"
                onClick={toggleDrawer(true)}
                color="text.secondary"
            >
                <Icon icon="material-symbols:menu" />
            </IconButton>
		
            {/* Quick Nav Drawer for all sub apps AND mobile nav */}
            <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {/* DESKTOP */}
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', flexDirection: false ? 'row-reverse' : 'row' }}>
                    <Typography 
                        variant='h6' 
                        sx={{ display: { xs: 'none', md: 'flex' }, color: "var(--pri-blue)", cursor: 'pointer', '&:hover': { color: "var(--btn-blue)" } }}
                        onClick={() => navigate("/")}
                    >
                        Other Tools & Resources
                    </Typography>
                    <IconButton
                        aria-label="close button"
                        onClick={toggleDrawer(false)}
                        color="text.secondary"
                    >
                        <Icon icon="material-symbols:close" />
                    </IconButton>
                </Box>
                {/* MOBILE */}
                <MobileNav navs={navs} onClickAction={toggleDrawer(false)} />
                <Container maxWidth="xs" sx={{ p: 2 }}>
                    <Typography 
                        variant='h6' 
                        sx={{ display: { xs: 'flex', md: 'none' }, color: "var(--pri-blue)", cursor: 'pointer' }} 
                        onClick={() => navigate("/")}
                        gutterBottom
                    >
                        Other Tools & Resources
                    </Typography>
                    <AppCardLinks withDesc={false} />
                </Container>
            </SwipeableDrawer>
        </>
    )
}