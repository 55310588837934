import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { IdToLabel } from "../../consts/formStructure";
import { sortObj } from "../../helpers/FormHelper";

const TABS = {
    g: "global",
    o: "onsite",
    t: "transport",
    h: "hotel",
}

export default function ResultInputs({ inputList }) {
    const [sorted, setSorted] = useState({});
    
    useEffect(() => {
        var sortedLists = inputList;
        Object.entries(inputList).forEach(([tab, field_list]) => {
        var sortedFieldList = (typeof field_list === 'object' && field_list !== null) ? sortObj(field_list) : field_list;
            sortedLists[tab] = sortedFieldList
        }, )
        setSorted(sortedLists);
    }, [inputList]);

    return (
        <Accordion defaultExpanded={false} >
            <AccordionSummary expandIcon={<ExpandMore />} >
                <Typography className="title-section">View Calculator Inputs</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    Object.entries(sorted).map(([tab, fields], index) => {
                        return (
                            <Grid container key={index}>
                                <Grid xs={12}>
                                    {   
                                        (typeof fields === 'object' && fields !== null) 
                                        ?
                                        <>
                                        <Typography>{">>"} {tab} </Typography>
                                            {  
                                            Object.entries(fields).map(([id_name, value], index) => {
                                                let field_name = (id_name in IdToLabel) ? IdToLabel[id_name] : id_name;
                                                return (
                                                    <Grid container key={index}>
                                                        <Grid xs={6}>
                                                            <Typography>{field_name}</Typography>
                                                        </Grid>
                                                        <Grid xs={6}>
                                                            <Typography>{value}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                        }
                                        </>
                                        :
                                        <Typography>{">>"} {tab.replace("res","").replace(/_/g," ",)} &emsp;{fields.toLocaleString('en-US')}</Typography>
                                    }
                                    <Typography>=============================</Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </AccordionDetails>
        </Accordion>
    )
}