import CalculatorPage from "../../pages/CalculatorPage";
import LandingPage from "../../pages/LandingPage";
import NotFoundPage2 from "../../pages/NotFoundPage";
import ResultPage from "../../pages/ResultPage";


const pathRoot = process.env.REACT_APP_CC_ROOT;
export const NAV_CONFIG_APP = {
    rootPath: pathRoot,
    routes:
    [
        {
            title: 'Home',
            path: pathRoot + "/",
            element: <LandingPage />,
        },
        {
            title: 'Calculator',
            path: pathRoot + "/calculator",
            element: <CalculatorPage />,
        },
        {
            title: '',
            path: pathRoot + "/results",
            element: <ResultPage />,
        },
        {
            title: '',
            path: pathRoot + "/*",
            element: <NotFoundPage2 />,
        },
    ]
}
