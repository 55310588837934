import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import PageFooter from "../PageFooter";
import ResponsiveNavBar from "../components/nav/ResponsiveNavBar";

export default function Base({ nav_config, title }) {
    document.title = "SGFIN | " + (title ? title : "Tools & Resources");
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                flexDirection: 'column',
                minHeight: '100vh', 
            }}
        >
            <ResponsiveNavBar navs={nav_config.filter(n => !(n?.isHidden || false))} />
            <Outlet />
            <PageFooter />
        </Box>
    )
}