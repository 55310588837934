import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// transport, accoms, onsite, total
const COLORS = ['#203864', '#800000', '#7F6000', '#7C9AD2'];
const COLORS_LIGHT = ['#7C9AD2', '#CA6A6A', '#C1A54F'];

function CustomTick({ x, y, payload }) {
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={4} fontSize={"0.8em"} textAnchor='end' >{payload.value}</text>
		</g>
	)
}

export default function ResultBarChart({ barDataObj, saveToPngDone }) {
	const [barData, setBarData] = useState([]);

    const setUpDataArray = (dataObj) => {
        let dataArray = [];
        Object.entries(dataObj).forEach( ([key, val]) => {
			let isSplit = val.value.length > 1;
            dataArray.push(
                {
                    label: val.label,
					isThisEvent: (key.split("_")[0] === "1"),
					Total: isSplit ? null : +val.value[0].toFixed(0),
					Transportation: isSplit ? +val.value[0].toFixed(0) : null,
					Accommodation: isSplit ? +val.value[1].toFixed(0) : null,
					Onsite: isSplit ? +val.value[2].toFixed(0) : null,
					// net: val.net ? +val.net.toFixed(0) : null
                }
            )
        });
        return dataArray;
    }

	const renderCustomizedLabel = (props) => {
		const { x, y, width, height, value } = props;
		return (
			<>
			{ value &&
			<g>
			<text x={x + width / 2} y={y + height / 2} fontSize={"0.8em"} fill="#fff" textAnchor="middle" dominantBaseline="middle">
			  	{value}
			</text>
			</g>
			}
			</>
		);
	};

	useEffect(() => {
        setBarData(setUpDataArray(barDataObj));
    },[barDataObj]);

	return (
	<ResponsiveContainer width="100%" height={280}>
		<BarChart
		layout='vertical'
		width="100%"
		data={barData}
		barCategoryGap={2}
		margin={{
			top: 5,
			right: 30,
			left: 60,
			bottom: 5,
		}}
		>
		<CartesianGrid strokeDasharray="3 3" />
		<XAxis type="number" />
		<YAxis type="category" dataKey="label" tick={<CustomTick />} />
		<Tooltip cursor={saveToPngDone && true} wrapperStyle={saveToPngDone ? {} : { visibility: 'hidden' }} />
		<Legend formatter={(value, entry, index) => (<span style={{ fontSize: '0.8em' }}>{value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}</span>)}/>
		<Bar dataKey="Transportation" stackId="a" fill={COLORS[0]} >
			{barData.map((entry, index) => (
				<Cell key={`cell-${index}`} fill={COLORS[0]} />
				// <Cell key={`cell-${index}`} fill={!entry.isThisEvent ? COLORS_LIGHT[0] : COLORS[0]} />
			))}
			<LabelList dataKey="Transportation" content={renderCustomizedLabel}/>
		</Bar>
		<Bar dataKey="Accommodation" stackId="a" fill={COLORS[1]} >
			{barData.map((entry, index) => (
				<Cell key={`cell-${index}`} fill={COLORS[1]} />
			))}
			<LabelList dataKey="Accommodation" content={renderCustomizedLabel}/>
		</Bar>
		<Bar dataKey="Onsite" stackId="a" fill={COLORS[2]} >
			{barData.map((entry, index) => (
				<Cell key={`cell-${index}`} fill={COLORS[2]} />
			))}
			<LabelList dataKey="Onsite" content={renderCustomizedLabel}/>
		</Bar>
		<Bar dataKey="Total" stackId="a" fill={COLORS[3]} >
			{barData.map((entry, index) => (
				<Cell key={`cell-${index}`} fill={COLORS[3]} />
			))}
			<LabelList dataKey="Total" content={renderCustomizedLabel}/>
		</Bar>
		{/* <Bar dataKey="net" fill={COLORS[3]} >
			<LabelList dataKey="net" content={renderCustomizedLabel}/>
		</Bar> */}
		</BarChart>
	</ResponsiveContainer>
	);
}
