import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import '../../../print.css';
import { sortObj } from '../../helpers/FormHelper';
import ResultBarChart from './ResultBarChart';

// to overwrite outdated result benchmarks
const overwriteBenchmarks = {
    "2_US_MICE": {
        "label": "US MICE Average",
        "value": [150.17, 14.13, 12.37],
    },
    "3_Singapore_Avg": {
        "label": "Singapore Average",
        "value": [24.4151],
    },
    "4_US_Avg": {
        "label": "US Average",
        "value": [40.9579],
    },
    "5_EU_Avg": {
        "label": "EU Average",
        "value": [16.9162],
    },
    "6_UK_Avg": {
        "label": "UK Average",
        "value": [12.9320],
    },
    "7_China_Avg": {
        "label": "China Average",
        "value": [21.8980],
    }
};


export default function ResultBenchmarks({ barDataObj }) {
    const imageTag = 'result_benchmarks';
    const [saveToPngDone, setSaveDone] = useState(false);
    
    const [sortedData, setSortedData] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenPopper = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(true);
    }
    const handleClosePopper = () => {
        setIsOpen(false);
    }
    
    useEffect(() => {
        setSortedData(sortObj({...barDataObj, ...overwriteBenchmarks}));
    }, [barDataObj]);

    return (
        <>
            <Box sx={{ display: 'flex', alignContent: 'center' }}>
            <Typography variant="h6" sx={{ fontSize: '1.1em' }} gutterBottom>
                Emission Intensity Benchmarks
                <span style={{ fontSize: 14, color: '#00000099' }}>
                &emsp;(kg CO<sub>2</sub>e)&emsp;
                </span>
            </Typography>
            <InfoOutlined style={{ color: '#00000099'}} className='infoIcon hide-on-print' onMouseEnter={handleOpenPopper} onMouseLeave={handleClosePopper} />
            </Box>
            <Popper id={"popper_res"} open={isOpen} anchorEl={anchorEl} placement={'left'} sx={{ width: '300px', zIndex: 1500 }}>
                <Box 
                    sx={{ margin: 1, p: 2, 
                        borderRight: '4px solid var(--pri-yellow)',
                        bgcolor: 'var(--bg-yellow)' }}
                >
                    <Typography variant="body2">
                    This calculator benchmarks the estimated event emission intensity (carbon footprint per person per day) against that of a typical MICE event in the United States and selected economies' general average emission levels.
                    </Typography>
                </Box>
            </Popper>

            <Box id={imageTag}>
                <ResultBarChart barDataObj={sortedData} saveToPngDone={saveToPngDone} />
            </Box>
        </>
    )
}