import Apartment from '@mui/icons-material/Apartment';
import ConnectingAirports from '@mui/icons-material/ConnectingAirports';
import Hail from '@mui/icons-material/Hail';
import Box from '@mui/material/Box';

export default function LoadingIcon() {
    return (
        <Box sx={{ 
            border: "3px solid var(--pri-blue)",
            borderRadius: "10px",
            display: 'flex',
            flexDirection: 'column',
            p: 1
        }}>
            <Box className="lds-calc-icons" sx={{ 
                border: "3px solid var(--pri-blue)",
                borderRadius: "5px",
                padding: 0.6,
                display: 'flex',
                mb: 0.5
            }}>
                <div className="loader-icon"><ConnectingAirports /></div>
                <div className="loader-icon"><Hail /></div>
                <div className="loader-icon"><Apartment /></div>
            </Box>
            <div className="lds-grid">
                <div></div><div></div><div></div>
                <div></div><div></div><div></div>
                <div></div><div></div><div></div>
            </div>
        </Box>
    )
}