import styled from '@emotion/styled';
import Flight from '@mui/icons-material/Flight';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import Language from '@mui/icons-material/Language';
import LocationCity from '@mui/icons-material/LocationCity';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const StyledTab = styled(Tab) ({
    minHeight: 'auto',
    '&:hover': { 
        backgroundColor: 'var(--btn-blue)',
        color: 'white'
    }
})

export default function CalcTabs({ position, stage, tabValue, handleTabChange }) {
    return (
        <Box sx={{ borderBottom: position==="top"?1:0, borderTop: position==="bottom"?1:0, borderColor: 'divider' }}>
            {
                stage === 1 ?

                <Tabs value={tabValue} onChange={handleTabChange} 
                    variant="scrollable" scrollButtons allowScrollButtonsMobile
                >
                    <StyledTab icon={<Language />} iconPosition="end" label="1. Global Inputs" />
                </Tabs>
                :

                <Tabs value={tabValue} onChange={handleTabChange} 
                    variant="scrollable" scrollButtons allowScrollButtonsMobile
                >
                    <StyledTab icon={<Language />} iconPosition="end" label="1. Global Inputs"/>
                    <StyledTab icon={<GroupsOutlined />} iconPosition="end" label="2. On-Site Activities" />
                    <StyledTab icon={<Flight />} iconPosition="end" label="3. Transportation" />
                    <StyledTab icon={<LocationCity />} iconPosition="end" label="4. Accommodation" />
                </Tabs>
            }
        </Box>
    )
}