import { Box, Unstable_Grid2 as Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useDebouncedCallback } from 'use-debounce';
import EllipsisLoader from "../../../carbonCalc/components/loaders/EllipsisLoader";
import { CCPContext } from "../../pages/CalculatorPage";
import ResultArea from "../items/ResultArea";
import SearchResultItem from "../items/SearchResultItem";
import SearchBar from "./SearchBar";

export default function SearchFeature() {
    const [selectedCompany, setSelectedCompany] = useState({});

    const [isCompareLayoutState] = useContext(CCPContext).layout;
    const [selectedCompanies, setSelectedCompanies] = useContext(CCPContext).selection;
    const [isSearchState, setSearchState] = useState(isCompareLayoutState);
    const [isLoading, setIsLoading] = useState(false);
    const [isResultOpen, setResultOpen] = useState(false);
    const [isError, setIsError] = useState(false);

    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState({});

    const handleClick = (e, clickedResult) => {
        setSearchState(false);
        setResultOpen(true);
        setSelectedCompany(clickedResult)
        setSelectedCompanies({ ...selectedCompanies, [clickedResult.id]: clickedResult });
    };
    const handleClose = (e, clickedId) => {
        setSelectedCompany({})
        setResultOpen(false); 
        if (isCompareLayoutState) setSearchState(true);
        
        let tempCompanies = selectedCompanies;
        if (clickedId in tempCompanies) delete tempCompanies[clickedId]; // delete the selected ID only
        setSelectedCompanies({ ...tempCompanies });
    };

    const handleFetch = useDebouncedCallback(() => {
        if (searchInput) {
            setIsLoading(true);
            const url = process.env.REACT_APP_CCP_LAMBDA + '?' + new URLSearchParams({ search_keyword: searchInput });
            fetch(url, 
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(
                function (response) {
                    setIsError(false);
                    setIsLoading(false);
                    return response.json();
                }
            ).then(
                function(data) {
                    setSearchResults(data);
                }
            ).catch(
                function(error) {
                    if (process.env.NODE_ENV === 'development') {
                        if (typeof error.json === "function") {
                            error.json().then(jsonError => {
                                console.log("Json error from API");
                                console.log(jsonError);
                            }).catch(genericError => {
                                console.log("Generic error from API");
                                console.log(error.statusText);
                            });
                        } else {
                            console.log("Fetch error");
                            console.log(error);
                        }
                    }
                    setIsLoading(false);
                    setIsError(true);
                }
            );
        }
    }, 300);
    
    return (
        <>
        <Grid container spacing={2} sx={{ justifyContent: 'space-between', width: '100%' }}>
            {(!isCompareLayoutState || isSearchState) && 
                <Grid xs={12}>
                <SearchBar searchInput={searchInput} handleFetch={handleFetch} setSearchInput={setSearchInput} setSearchState={setSearchState}  />
                </Grid>
            }
            {
                (isSearchState && ((isLoading || searchInput || isError) || (isCompareLayoutState && isResultOpen))) &&
                <Grid xs={12} sm={(isSearchState && !isResultOpen) ? 12 : 6}>
                    <Box className='ccp_white_box'>
                    <Box sx={{ backgroundColor: '#0000000b', height: isLoading ? 'auto' : 0, transition: 'all .5s', display: 'flex', justifyContent: 'center', px: isLoading && 3, py: isLoading && 2, borderBottom: isLoading && '1px solid #fff' }} >
                        { isLoading && <EllipsisLoader />}
                    </Box>
                    {
                        (isError) ?
                            <Box sx={{ px: 3, py: 2 }} >
                            Currently experiencing technical difficulties in fetching results.
                            <Typography variant='subtitle2' >
                            Please try again later.
                            </Typography>
                            </Box>
                        : (
                        (searchInput && Object.keys(searchResults).length === 0) ?
                            <Box sx={{ px: 3, py: 2 }} >
                                No matches found.
                                <Typography variant='subtitle2' >
                                    Please try a different keyword.
                                </Typography>
                            </Box>
                        :
                            Object.entries(searchResults).map(([id, resultObj], index) => {
                                let key = id + "_" + index;
                                return (
                                    <SearchResultItem key={key} resultObj={resultObj} handleClick={handleClick} /> //canCompare={canCompare && !(id in selectedCompanies)} 
                                )
                            })
                        )
                    }
                    </Box>
                </Grid>
            }
            {
                isResultOpen &&
                <Grid xs={12} sm={(isCompareLayoutState || (isResultOpen && !isSearchState)) ? 12 : 6}>
                    <ResultArea selectedCompany={selectedCompany} handleClose={handleClose} />    
                </Grid>
            }
            </Grid>
            </>
    )
}