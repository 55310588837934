import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const resultStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    px: 3,
    py: 2,
    cursor: 'pointer',
    borderBottom: '1px solid #fff',
    '&:hover': {
        backgroundColor: 'white',
    },
};

export default function SearchResultItem({ resultObj, handleClick }) {
    const compname = resultObj.compname;
    const ticker = resultObj.ticker;
    const exchange = resultObj.exchange;
    const country = resultObj.country;

    return (
        <Box sx={resultStyle} onClick={(e) => handleClick(e, resultObj)} >
            <Box>
                {compname}
                <Typography variant='subtitle2' >
                    {ticker} { exchange && `• ${exchange}`} { country && `(${country})`} 
                </Typography>
            </Box>
        </Box>
    )
}