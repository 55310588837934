import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { sortObj } from '../../helpers/FormHelper';
// import PieChartLabelToggle from './PieChartLabelToggle';
// import ResultPieChart from './ResultPieChart';
import ResultPiePlot from './ResultPiePlot';

export default function ResultBreakdown({ outerRing, innerRing }) {
    // const [labelsVisible_total, setLabelsVisible_total] = useState('inner');
    // const [labelsVisible_onsite, setLabelsVisible_onsite] = useState('outer');
    
    const [sortedOuterPieData, setSortedOuterPieData] = useState({});
    const [sortedInnerPieData, setSortedInnerPieData] = useState({});
    useEffect(() => {
        setSortedOuterPieData(sortObj(outerRing, true));
        setSortedInnerPieData(sortObj(innerRing, true));
    }, [outerRing, innerRing]);

    return (
        <>
        <Typography variant="h6" sx={{ fontSize: '1.1em' }} >
            Carbon Footprint Breakdown
            <span style={{ fontSize: 14, color: '#00000099' }}>
            &emsp;(kg CO<sub>2</sub>e)
            </span>
        </Typography>
        <Typography variant="caption" sx={{ fontSize: 11 }} color="text.secondary">
            Click into each sector to view the further breakdown within each category.
        </Typography>

        <Box>
            <ResultPiePlot imageTag={'result_piechart'} outerRing={sortedOuterPieData} innerRing={sortedInnerPieData} />
        </Box>
        
        {/* <Grid container spacing={1} sx={{ height: '90%' }}>
            <Grid xs={12} lg={6} order={{ xs: 1 }}  className="hide-on-print">
                <PieChartLabelToggle labelsVisible={labelsVisible_total} setLabelsVisible={setLabelsVisible_total} />
            </Grid>
            <Grid xs={12} lg={6} order={{ xs: 2, lg: 3 }}>
                <ResultPieChart imageTag={'result_piechart'} outerRing={sortedOuterPieData} innerRing={sortedInnerPieData} labelsVisible={labelsVisible_total} resultImages={resultImages} setResultImages={setResultImages}/>
            </Grid>
            <Grid xs={12} lg={6} order={{ xs: 3, lg: 2 }} className="hide-on-print">
                <PieChartLabelToggle labelsVisible={labelsVisible_onsite} setLabelsVisible={setLabelsVisible_onsite} disabledButtons={["inner"]} />
            </Grid>
            <Grid xs={12} lg={6} order={{ xs: 4 }}>
                <ResultPieChart 
                    imageTag={'result_piechart_onsite'}
                    isCategorized={false}
                    outerRing={Object.keys(sortedOuterPieData).filter((key) => key[0]==="3").reduce((obj, key) => {obj[key] = sortedOuterPieData[key]; return obj; }, {})}
                    innerRing={Object.keys(sortedInnerPieData).filter((key) => key[0]==="3").reduce((obj, key) => {obj[key] = sortedInnerPieData[key]; return obj; }, {})}
                    labelsVisible={labelsVisible_onsite} resultImages={resultImages} setResultImages={setResultImages}
                />
            </Grid>
        </Grid> */}
        </>
    )
}