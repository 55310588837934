import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext } from "react";
import { OverallDataContext } from '../../pages/CalculatorPage';

export default function SelectField({ id_name, tabName, onChangeHandler, options, setFocus, isReadOnly }) {
    const [overallData, setData] = useContext(OverallDataContext).allData;

    const handleOnChange = (field_id, field_value) => {
        let fieldData = {
            name: field_id,
            value: field_value,
        }
        onChangeHandler(fieldData);
    }
    return (
        <Select name={ id_name } id={ id_name } 
            value={overallData?.[tabName]?.[id_name] ?? ""}
            onChange={event => handleOnChange(event.target.name, event.target.value)}
            aria-labelledby={id_name}
            defaultValue={""}
            fullWidth
            size="small"
            readOnly={isReadOnly}
            disabled={isReadOnly}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
        >
            <MenuItem disabled value="">Choose one option from below:</MenuItem>
            {
                options.map((opt, idx) => {
                    const opt_label = opt.label ? opt.label : opt.value;
                    return (
                        <MenuItem key={idx} value={opt.value}>{opt_label}</MenuItem>
                    )
                })
            }
        </Select>
    )
}