import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { CCPContext } from "../../pages/CalculatorPage";
import SliderInput from "./SliderInput";

const boxStyle = {
    px: 3,
    py: 2,
};

const DownArrow = () => {
    return (
        <Box sx={{ p: 1, borderRadius: 1, backgroundColor: '#ffdfdb ', color: '#C5221F', border: '1px white solid' }}>
            <ArrowDownward sx={{ fontSize: 'medium' }} />
        </Box>
    )
}
const UpArrow = () => {
    return (
        <Box sx={{ p: 1, borderRadius: 1, backgroundColor: '#d7ffe3 ', color: '#137333', border: '1px white solid' }}>
            <ArrowUpward sx={{ fontSize: 'medium' }} />
        </Box>
    )
}

export default function CompanyResultItem({ resultObj, handleClose }) {
    const [isCompareLayoutState, setCompareLayoutState] = useContext(CCPContext).layout;
    const masterSliderValue = useContext(CCPContext).masterSliderValue;

    const res_id = resultObj.id;
    const compname = resultObj.compname;
    const ticker = resultObj.ticker;
    const exchange = resultObj.exchange;
    const country = resultObj.country;
    const sensitivity = resultObj.sensitivity;
    const ghg = resultObj.ghg;
    const mcap = resultObj.mcap;

    const [priceChange, setPriceChange] = useState(0);
    const [sliderValue, setSliderValue] = useState(masterSliderValue);

    useEffect(() => {
        setSliderValue(masterSliderValue);
    }, [masterSliderValue]);
    
    useEffect(() => {
        // for linear rs, this formula will change for future iteration
        setPriceChange(sensitivity * (ghg * sliderValue) / mcap);
    }, [resultObj, sliderValue]);

    return (
        <>
        <Box sx={{ ...boxStyle, fontSize: '30px', borderBottom: '2px solid #fff'}} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                {compname}
                <Typography variant='subtitle2' >
                    {ticker} { (ticker && (exchange || country)) && '•'} {exchange} { country && `(${country})`}
                </Typography>
                </Box>
                <Close sx={{color: 'text.secondary', '&:hover': { color: 'text.primary'}}} onClick={(e) => handleClose(e, res_id)} />
            </Box>
        </Box>
        {!isCompareLayoutState && <SliderInput value={sliderValue} valueSetter={setSliderValue} />}
        <Box sx={boxStyle} >
            If GHG Emission (Scopes 1+2) decreases by:  
            <Stack direction='row' spacing={2} alignItems='center' sx={{ mb: 2 }}>
                <DownArrow />
                <Typography variant='h4'>
                    {sliderValue} %
                </Typography>
            </Stack>

            { priceChange>=0 ? 
                <>
                Our model estimates the stock price will change by:
                <Stack direction='row' spacing={2} alignItems='center'>
                    {priceChange>=0 ? <UpArrow /> : <DownArrow /> }
                    <Typography variant='h4'>
                        &#8776; {priceChange >= 0 ? "+" : ""}{priceChange.toFixed(4)} %
                    </Typography>
                </Stack>
                </>
                :
                <b>
                Our model does not predict positive price change.
                <br />
                </b>
            }
            <br />
            Click <a href={'/#' + process.env.REACT_APP_CCP_ROOT + '/technotes'} className="iconLink" style={{ textDecoration: 'underline' }}>here</a> to read more about our model.
        </Box>
        </>
    )
}