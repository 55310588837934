import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CalcAccordion({ children, title, sectionTag, isOpen }) {
    return (
        <Accordion defaultExpanded={isOpen}
            sx={{
                boxShadow: 'none',
                border: '1px solid #e2e2e2',
                ':before': {
                    display: 'none'
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={sectionTag+"panel1a-content"}
                id={sectionTag+"panel1a-header"}
                >
                <Typography className="title-section">{ title }</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ marginBottom: 2 }}>
                    <Typography variant='caption' color={'var(--btn-blue)'}>Unless otherwise stated, please fill in the blank with a precise value, or select a range that best fits your estimated amount. If the data is not filled, the calculator will estimate footprint based on default baseline values.</Typography>
                </Box>
                { children }
            </AccordionDetails>
        </Accordion>
    )
}