import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const spanStyle = {
	display: 'inline-block',
	whiteSpace: 'nowrap',
	verticalAlign: 'top',
	overflow: 'hidden',
	marginRight: '4px',
	transition: 'clip-path .4s ease-in-out',
	clipPath: 'inset(0 0 0 100%)',
}

export default function IconButton({ id, icon, onClickAction, label="" }) {
	const iconLabelId = 'slide-left-'+id+label.split(" ").join("-");
	const handleHover = () => {
		document.getElementById(iconLabelId).style.clipPath = 'inset(0)';
	};
	const handleLeave = () => {
		document.getElementById(iconLabelId).style.clipPath = 'inset(0 0 0 100%)';
	}
    return (
        <Box 
            onClick={onClickAction} 
			onMouseOver={handleHover}
			onMouseOut={handleLeave}
			className="iconLink"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 1,
            }}
        >
            <Typography variant='subtitle' display='inline' id={iconLabelId} sx={spanStyle} >
			{label}
			</Typography>
			{icon}
        </Box>
    )
}