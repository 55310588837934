import { Close } from "@mui/icons-material";
import { Autocomplete, Box, Container, FormControl, FormLabel, Unstable_Grid2 as Grid, IconButton, TextField, Typography } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { dataGridColumns } from "../consts/dataConsts";

const ODD_OPACITY = 0.2;

export default function DataHubPage({ dataGridRows=[], dataGridFilters={}, isLoading=true }) {
    const [countryOptions, setCountryOptions] = useState([]);
    const [sectorOptions, setSectorOptions] = useState([]);
    const [tickerOptions, setTickerOptions] = useState([]);

    const [displayedRows, setDisplayedRows] = useState([]);
    const [filterCountry, setCountry] = useState('');
    const [filterSector, setSector] = useState('');
    const [filterTicker, setTicker] = useState('');

    useEffect(()=>{
        // Filter rows
        let filteredRows = dataGridRows;
        if (filterCountry) filteredRows = filteredRows.filter(row => row?.country === filterCountry);
        if (filterSector) filteredRows = filteredRows.filter(row => row?.sector_name === filterSector);
        if (filterTicker) filteredRows = filteredRows.filter(row => row?.ticker === filterTicker);
        setDisplayedRows(filteredRows)

        // Filter dropdown options
        setCountryOptions(dataGridFilters?.country)
        // Set sector options, filtered by country if selected
        const availableSectors = filterCountry
        ? Object.keys(dataGridFilters?.filterHierarchy?.[filterCountry] || {})
        : dataGridFilters?.sector_name || [];
        setSectorOptions(availableSectors);

        // Set ticker options, filtered by both country and sector if selected
        const availableTickers =
        filterCountry && filterSector
            ? dataGridFilters?.filterHierarchy?.[filterCountry]?.[filterSector] || []
            : filterCountry
            ? Object.values(dataGridFilters?.filterHierarchy?.[filterCountry] || {}).flat()
            : dataGridFilters?.ticker || [];
        setTickerOptions(availableTickers);
    }, [dataGridRows, dataGridFilters, filterCountry, filterSector, filterTicker]);

    useEffect(()=>{
        setDisplayedRows(dataGridRows)
    }, [dataGridRows]);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={{ xs: 1, sm: 2}} sx={{ alignItems: 'end' }}>
                <Grid xs={12} sm={1.5}>
                    <Typography gutterBottom>Filter results:</Typography>
                </Grid>
                <Grid xs={12} sm={3.5}>
                <FilterSelect
                    idname='country_field'
                    label='Country'
                    value={filterCountry}
                    valueSetter={setCountry}
                    options={countryOptions || []}
                    dropdownOnly={true}
                />
                </Grid>
                <Grid xs={12} sm={3.5}>
                <FilterSelect
                    idname='sector_field'
                    label='Sector'
                    value={filterSector}
                    valueSetter={setSector}
                    options={sectorOptions || []}
                    dropdownOnly={true}
                />
                </Grid>
                <Grid xs={12} sm={3}>
                <FilterSelect
                    idname='ticker_field'
                    label='Ticker'
                    value={filterTicker}
                    valueSetter={setTicker}
                    options={tickerOptions || []}
                    dropdownOnly={false}
                />
                </Grid>
                <Grid xs={12} sm={0.5}>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        sx={{ mb: 1 }}
                        onClick={()=>{ setCountry(''); setSector(''); setTicker(''); }}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
            <Box sx={{ height: "85vh", pt: 2 }}>
                <StripedDataGrid 
                    rows={displayedRows} 
                    columns={dataGridColumns} 
                    pageSizeOptions={[100]}
                    // disableColumnSorting
                    rowHeight={25}
                    loading={isLoading}
                    slotProps={{
                        loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton',
                        },
                    }}
                    getRowClassName={(params) =>{
                        var groupIndex = Math.floor(params.indexRelativeToCurrentPage / 4);
                        return groupIndex % 2 === 0 ? 'even' : 'odd';
                    }}
                />
            </Box>
        </Container>
    )
}

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
            backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY +
                theme.palette.action.selectedOpacity +
                theme.palette.action.hoverOpacity,
            ),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
            },
            },
        },
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
        fontWeight: 'bold',
    },
    '& .col-centered': {
        textAlign: 'center'
    },
    '& .col-grey': {
        backgroundColor: '#d0d0d0',
    },
    '& .col-green': {
        backgroundColor: '#99FF99',
    },
    '& .col-red': {
        backgroundColor: '#E6B8B7',
    },
    '& .col-cream': {
        backgroundColor: '#FFFFCC',
    },
    '& .col-blue': {
        backgroundColor: '#B7DEE8',
    },
    '& .col-yellow': {
        backgroundColor: '#FFFF89',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
}));

const FilterSelect = ({ idname, value, label, valueSetter, options, dropdownOnly=false }) => {
    const [localValue, setValue] = useState(value);
    const placeholder = `${ !dropdownOnly ? 'Search' : 'Select filter' }`

    useEffect(()=>{
        setValue(value)
    },[value])

    return (
        <FormControl fullWidth>
            <FormLabel htmlFor={idname} id={`${idname}-label`} sx={{ fontSize: '0.8em', pl: 2 }}>{label}</FormLabel>
            <Autocomplete
                freeSolo={!dropdownOnly}
                name={idname}
                options={["", ...options]}
                value={localValue}
                onChange={(event, newValue) => {
                    setValue(newValue || '');
                    valueSetter(newValue || '');
                }}
                inputValue={localValue}
                onInputChange={(event, newInputValue) => {
                    setValue(newInputValue || '');
                }}
                renderInput={(params) => 
                    <TextField 
                        {...params}
                        placeholder={placeholder}
                        aria-label={placeholder}
                        fullWidth
                    />
                }
                onBlur={()=>{
                    if (!value) {
                        setValue('');
                        valueSetter('');
                    }
                }}
            />
        </FormControl>
    )
}