import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import SliderInput from "../components/items/SliderInput";
import SearchFeature from "../components/search/SearchFeature";
import landing from '../images/landing.webp';

export const CCPContext = createContext();

export default function CalculatorPage() {
    const [isCompareLayoutState, setCompareLayoutState] = useState(false);
    const searchCount = (isCompareLayoutState) ? 3 : 1;
    const [selectedCompanies, setSelectedCompanies] = useState({});

    const sliderDefault = 15;
    const [masterSliderValue, setMasterSliderValue] = useState(sliderDefault);

    useEffect(() => {
        if (Object.keys(selectedCompanies).length === 0) setCompareLayoutState(false);
    },[selectedCompanies])

    return (
        <CCPContext.Provider value={{layout: [isCompareLayoutState, setCompareLayoutState], selection: [selectedCompanies, setSelectedCompanies], masterSliderValue: masterSliderValue}} >
            <Box sx={{ background: `var(--bg-light) url(${landing}) no-repeat center/cover fixed`, width: '100%', minHeight: '90vh' }}>
                <Container maxWidth={isCompareLayoutState ? "xl" : "lg"} sx={{ py: '2em' }} >
                    <Stack spacing={2} direction="column" alignItems="center">
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='h3' >
                                CORPORATE EMISSION PRICING CALCULATOR
                            </Typography>
                            <Typography variant='h6' gutterBottom>
                                v0.2
                            </Typography>
                        </Box>
                        {
                            isCompareLayoutState &&
                            <>
                            <Typography variant='h6' gutterBottom>
                                Select and compare up to 3 companies
                            </Typography>
                            <Box className='ccp_white_box' >
                                <SliderInput value={masterSliderValue} valueSetter={setMasterSliderValue} />
                            </Box>
                            </>
                        }
                        <Grid container spacing={2} sx={{ boxSizing: 'border-box', justifyContent: 'space-between', width: '100%' }}>
                        {
                            Array.from(Array(searchCount), (e, key) => {
                                return (
                                    <Grid xs={12/searchCount} key={key}>
                                        <SearchFeature />
                                    </Grid>
                                )
                            })
                        }
                        <Grid xs={12} >
                            <Box className='ccp_white_box' sx={{ px: 3, py: 2, textAlign: 'justify' }}>
                                <Typography variant='subtitle2' gutterBottom>
                                    DISCLAIMERS:
                                </Typography>
                                <Typography variant="caption">
                                    The model was estimated using information as of Dec. 2022.<br/>
                                </Typography>
                                <Typography variant="caption">
                                    The values generated by this calculator are intended solely for illustrative, knowledge-sharing, and educational purposes. These estimates should not be considered as a substitute for professional financial advice. We advise users not to rely on these figures for making financial decisions. Your feedback and comments are highly valued. You may contact us at <a href="mailto:sgfin@nus.edu.sg?subject=Corporate Emission Pricing Calculator - User Enquiry">sgfin@nus.edu.sg</a> for any enquiries.
                                </Typography>
                            </Box>
                        </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </CCPContext.Provider>
    )
}