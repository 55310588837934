import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { CCPContext } from "../../pages/CalculatorPage";
import CompanyResultItem from "../items/CompanyResultItem";

export default function ResultArea({ selectedCompany, handleClose }) {
    const ALLOW_COMPARISON = false;
    const [isCompareLayoutState, setCompareLayoutState] = useContext(CCPContext).layout;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box className='ccp_white_box' >
            <CompanyResultItem resultObj={selectedCompany} handleClose={handleClose} />
        </Box>
        { (ALLOW_COMPARISON && !isCompareLayoutState) && <Button sx={{ mx: 'auto', mt: 2 }} className='pill_button secondary_button' onClick={(e) => setCompareLayoutState(true)}>Compare Companies</Button> }
        </Box>
    )
}