import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";

export default function FormInputPrompt({ id_name, content }) {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenPopper = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(true);
    }
    const handleClosePopper = () => {
        setIsOpen(false);
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <Box className='centered-container'>
        {  content && 
            <Typography color="text.secondary" className='infoIcon'>
                <InfoOutlined onMouseEnter={handleOpenPopper} onMouseLeave={handleClosePopper} />
                <Popper id={"popper_"+id_name} open={isOpen} anchorEl={anchorEl} placement={isMobile ? 'left' : 'right'} sx={{ width: '400px', maxWidth: '80vw', zIndex: 1500 }}>
                    <Box 
                        sx={{ margin: 1, p: 2, 
                            borderLeft: isMobile ? '' : '4px solid var(--pri-yellow)', 
                            borderRight: isMobile ? '4px solid var(--pri-yellow)': '', 
                            bgcolor: 'var(--bg-yellow)' }}
                    >
                        <Typography variant='caption'>{ content }</Typography>
                    </Box>
                </Popper>
            </Typography>
        }
        </Box>
    )
}