import Box from '@mui/material/Box';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

const COLORS = ['#14993E', '#9A9A9A'];

const renderOuterActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, label, index } = props;

    const percLabel = `${(percent*100).toLocaleString('en-US', {maximumFractionDigits: 1})}%`;
    const textLabel = label.split("(")[0].trim();

    return (
        <g>
            <text x={cx} y={cy} dy={32} textAnchor="middle" fill={fill} fontSize={"2.2em"} fontWeight={700}>
                {index === 0 && percLabel}
            </text>
            <text x={cx} y={cy} dy={48} textAnchor="middle" fill={fill}>
                {index === 0 && textLabel}
            </text>
            <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={COLORS[index % COLORS.length]}
            />
        </g>
    );
};

export default function ResultGaugeChart({ imageTag, pieData }) {
return (
    <Box id={imageTag}>
        <ResponsiveContainer className="chart_container" height={"99%"} aspect={2.5}>
            <PieChart 
            {...{
                overflow: 'visible'
            }}>
                <Pie 
                    data={pieData} dataKey="value" cx="50%" cy="50%" 
                    startAngle={180} endAngle={0}
                    innerRadius="45%"
                    paddingAngle={0.6}
                    labelLine={false}
                    label={(props) => renderOuterActiveShape(props)}
                >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-outer-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    </Box>
);
}
