import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { OverallDataContext } from '../../pages/CalculatorPage';
import StepperButtons from "./StepperButtons";

export default function InputStepperField({ id_name, tabName, checkInputValue, isRequired, maxValue=null, step=1, errorMsg, setFocus, isReadOnly }) {
    const overallData = useContext(OverallDataContext).allData[0];
    const [fieldValue, setFieldValue] = useState('');
    const [tempPlaceholder, setPlaceholder] = useState('');
    
    const incrementVal = (field_name) => {
        var currNumVal = parseInt(fieldValue);
        var newVal = currNumVal;
        if (fieldValue === '') newVal = parseInt(tempPlaceholder + step)
        else if (maxValue === null || fieldValue < maxValue) newVal = parseInt(currNumVal + step);
        setFieldValue(newVal.toString());
        checkInputValue(field_name, newVal);
    }
    const decrementVal = (field_name) => {
        var currNumVal = parseInt(fieldValue);
        var newVal = currNumVal;
        if (fieldValue === '') newVal = parseInt(tempPlaceholder - step)
        else if (maxValue !== null && currNumVal > maxValue) newVal = parseInt(maxValue);
        else if (maxValue === null || fieldValue > 0) newVal = parseInt(currNumVal - step);
        setFieldValue(newVal.toString());
        checkInputValue(field_name, newVal);
    }
    
    const onChange = (event) => {
        var newVal = event.target.value === "" ? 0 : event.target.value;
        checkInputValue(event.target.name, newVal);
        setFieldValue(newVal.toString());
    }
    useEffect(() => {
        try {
            var tempPlaceholder = overallData[tabName][id_name].toString();
            setPlaceholder(parseInt(overallData[tabName][id_name]));
            setFieldValue(tempPlaceholder.toString());
        }
        catch {}
    }, [overallData]);

    return (
        <TextField
            type="numeric"
            name={ id_name } id={ id_name }
            value={fieldValue}
            placeholder={tempPlaceholder.toString()}
            required={isRequired}
            onChange={onChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            InputProps={{
                readOnly: isReadOnly,
                endAdornment: isReadOnly ? <></> : <InputAdornment position="end"><StepperButtons incrementVal={() => incrementVal(id_name)} decrementVal={() => decrementVal(id_name)} /></InputAdornment>
            }}
            inputProps={{
                autoComplete: 'off'
            }}
            aria-labelledby={id_name}
            error={(errorMsg !== '')}
            helperText={errorMsg ? errorMsg : ''}
            fullWidth
            sx={{ paddingY: 0 }}
            size="small"
        />
    )
}