import { Box, Slider, Typography } from "@mui/material";
import { useEffect } from "react";

export default function SliderInput({ value, valueSetter }) {
    const sliderDefault = 15;
    useEffect(() => {
        valueSetter(sliderDefault);
    }, [valueSetter]);

    return (
        <Box sx={{ px: 3, py: 2 }} >
            <Typography variant="subtitle2" fontWeight={400} id="sliderLabel">
                Adjust the slider below to indicate the percentage reduction of GHG Emissions.
            </Typography>
            <Box sx={{ px: 2 }} >
                <Slider defaultValue={sliderDefault} min={10} max={25} marks={[ { value: 10, label: '10%'}, { value: 25, label: '25%'}, { value: value, label: `${value}%`} ]} aria-labelledby="sliderLabel" onChange={(e,num) => valueSetter(num)} />
            </Box>
        </Box>
    )
}